import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import LoaderImage from "../../app-assets/images/icons/loaderIcons.gif";
import SideNav from "../../component/SideNavigator";
import Header from "../../component/TopHeader";
import verifyUser from "../Verifier";
import "./settingPage.css";

import { FormattedMessage, useIntl } from "react-intl";
import { Spinner } from "reactstrap";
import { ROUTES } from "../../constants/routes";

const SettingPage = (props) => {
	const MySwal = withReactContent(Swal);
	const intl = useIntl();
	const [firstTabFlag, setFirstTabFlag] = useState(true);
	const [secondTabFlag, setSecondTabFlag] = useState(false);
	const [thirdTabFlag, setThirdTabFlag] = useState(false);
	const [fourthTabFlag, setFourthTabFlag] = useState(false);
	const [secCheckboxFlag, setSecCheckboxFlag] = useState(false);
	const [loaderFlag, setLoaderFlag] = useState(false);
	const [companyUserData, setCompanyUserData] = useState([]);
	const [headerEmail, setHeaderEmail] = useState("");
	const [designation, setDesignation] = useState("");
	const [userRole, setUserRole] = useState("");
	const [offerNotification, setOfferNotification] = useState("");
	const [marketingNotification, setMarketingNotification] = useState("");
	const [networkNotification, setNetworkNotification] = useState("");
	const [analyticsNotification, setAnalyticsNotification] = useState("");
	const [modal, setModal] = useState(false);
	const [ipRange, setIpRange] = useState("");
	const [showError, setShowError] = useState(false);
	const [designationArrayvalue, setDesignationArrayValue] = useState([]);
	const [loading, setLoading] = useState(true);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const handleFirstTab = () => {
		setFirstTabFlag(true);
		setSecondTabFlag(false);
		setThirdTabFlag(false);
		setFourthTabFlag(false);
		getCompanyUserData();
	};
	const handleSecondTab = () => {
		setFirstTabFlag(false);
		setSecondTabFlag(true);
		setThirdTabFlag(false);
		setFourthTabFlag(false);
	};
	const handleThirdTab = () => {
		setFirstTabFlag(false);
		setSecondTabFlag(false);
		setThirdTabFlag(true);
		setFourthTabFlag(false);
	};
	const handleFourthTab = () => {
		handleNetworkIpData();
		setFirstTabFlag(false);
		setSecondTabFlag(false);
		setThirdTabFlag(false);
		setFourthTabFlag(true);
	};

	const handleNetworkIpData = () => {
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/getNetworkIp`,
			withCredentials: true,
		})
			.then((response) => {
				if (response.status === 200) {
					setIpRange(response.data.data[0]);
				}
			})
			.catch((err) => {
				toast.error(
					<FormattedMessage id="Something went wrong, please try again later" />,
				);
			});
	};

	const handleCheckBox = (e) => {
		if (offerNotification === "on") {
			return MySwal.fire({
				title: intl.formatMessage({ id: "Notification Update" }),
				text: intl.formatMessage({
					id: "Are you sure, you want to update it?",
				}),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: intl.formatMessage({ id: "Update" }),
				customClass: {
					confirmButton: "btn btn-snapchat border-dark border-25",
					cancelButton: "btn btn-outline-danger ml-1 border-25",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					axios({
						method: "POST",
						url:
							`${process.env[
								`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`
							]}/changeNotificationStatus`,
						data: {
							offers_notification_flag: "off",
						},
						withCredentials: true,
					})
						.then((response) => {
							if (response.status === 200) {
								toast.success(<FormattedMessage id="Status updated" />);
								getNotificationsDetail();
							}
						})
						.catch((err) => {
							toast.error(
								<FormattedMessage id="Something went wrong, please try again later" />,
							);
						});
				}
			});
		}
			return MySwal.fire({
				title: intl.formatMessage({ id: "Notification Update" }),
				text: intl.formatMessage({
					id: "Are you sure, you want to update it?",
				}),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: intl.formatMessage({ id: "Update" }),
				customClass: {
					confirmButton: "btn btn-snapchat border-dark border-25",
					cancelButton: "btn btn-outline-danger ml-1 border-25",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					axios({
						method: "POST",
						url:
							`${process.env[
								`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`
							]}/changeNotificationStatus`,
						data: {
							offers_notification_flag: "on",
						},
						withCredentials: true,
					})
						.then((response) => {
							if (response.status === 200) {
								toast.success(<FormattedMessage id="Status updated" />);
								getNotificationsDetail();
							}
						})
						.catch((err) => {
							toast.error(
								<FormattedMessage id="Something went wrong, please try again later" />,
							);
						});
				}
			});
	};

	const handleSecondCheckbox = () => {
		setSecCheckboxFlag(!secCheckboxFlag);
		if (marketingNotification === "on") {
			return MySwal.fire({
				title: intl.formatMessage({ id: "Notification Update" }),
				text: intl.formatMessage({
					id: "Are you sure, you want to update it?",
				}),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: intl.formatMessage({ id: "Update" }),
				customClass: {
					confirmButton: "btn btn-snapchat border-dark border-25",
					cancelButton: "btn btn-outline-danger ml-1 border-25",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					axios({
						method: "POST",
						url:
							`${process.env[
								`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`
							]}/changeNotificationStatus`,
						data: {
							marketing_notification_flag: "off",
						},
						withCredentials: true,
					})
						.then((response) => {
							if (response.status === 200) {
								toast.success(<FormattedMessage id="Status updated" />);
								getNotificationsDetail();
							}
						})
						.catch((err) => {
							toast.error(
								<FormattedMessage id="Something went wrong, please try again later" />,
							);
						});
				}
			});
		}
			return MySwal.fire({
				title: intl.formatMessage({ id: "Notification Update" }),
				text: intl.formatMessage({
					id: "Are you sure, you want to update it?",
				}),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: intl.formatMessage({ id: "Update" }),
				customClass: {
					confirmButton: "btn btn-snapchat border-dark border-25",
					cancelButton: "btn btn-outline-danger ml-1 border-25",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					axios({
						method: "POST",
						url:
							`${process.env[
								`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`
							]}/changeNotificationStatus`,
						data: {
							marketing_notification_flag: "on",
						},
						withCredentials: true,
					})
						.then((response) => {
							if (response.status === 200) {
								toast.success(<FormattedMessage id="Status updated" />);
								getNotificationsDetail();
							}
						})
						.catch((err) => {
							toast.error(
								<FormattedMessage id="Something went wrong, please try again later" />,
							);
						});
				}
			});
	};

	const handleThirdCheck = () => {
		if (networkNotification === "on") {
			return MySwal.fire({
				title: intl.formatMessage({ id: "Notification Update" }),
				text: intl.formatMessage({
					id: "Are you sure, you want to update it?",
				}),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: intl.formatMessage({ id: "Update" }),
				customClass: {
					confirmButton: "btn btn-snapchat border-dark border-25",
					cancelButton: "btn btn-outline-danger ml-1 border-25",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					axios({
						method: "POST",
						url:
							`${process.env[
								`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`
							]}/changeNotificationStatus`,
						data: {
							network_notification_flag: "off",
						},
						withCredentials: true,
					})
						.then((response) => {
							if (response.status === 200) {
								toast.success(<FormattedMessage id="Status updated" />);
								getNotificationsDetail();
							}
						})
						.catch((err) => {
							toast.error(
								<FormattedMessage id="Something went wrong, please try again later" />,
							);
						});
				}
			});
		}
			return MySwal.fire({
				title: intl.formatMessage({ id: "Notification Update" }),
				text: intl.formatMessage({
					id: "Are you sure, you want to update it?",
				}),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: intl.formatMessage({ id: "Update" }),
				customClass: {
					confirmButton: "btn btn-snapchat border-dark border-25",
					cancelButton: "btn btn-outline-danger ml-1 border-25",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					axios({
						method: "POST",
						url:
							`${process.env[
								`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`
							]}/changeNotificationStatus`,
						data: {
							network_notification_flag: "on",
						},
						withCredentials: true,
					})
						.then((response) => {
							if (response.status === 200) {
								toast.success(<FormattedMessage id="Status updated" />);
								getNotificationsDetail();
							}
						})
						.catch((err) => {
							toast.error(
								<FormattedMessage id="Something went wrong, please try again later" />,
							);
						});
				}
			});
	};

	const hanndleFourthCheck = () => {
		if (analyticsNotification === "on") {
			return MySwal.fire({
				title: intl.formatMessage({ id: "Notification Update" }),
				text: intl.formatMessage({
					id: "Are you sure, you want to update it?",
				}),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: intl.formatMessage({ id: "Update" }),
				customClass: {
					confirmButton: "btn btn-snapchat border-dark border-25",
					cancelButton: "btn btn-outline-danger ml-1 border-25",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					axios({
						method: "POST",
						url:
							`${process.env[
								`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`
							]}/changeNotificationStatus`,
						data: {
							analytics_notification_flag: "off",
						},
						withCredentials: true,
					})
						.then((response) => {
							if (response.status === 200) {
								toast.success(<FormattedMessage id="Status updated" />);
								getNotificationsDetail();
							}
						})
						.catch((err) => {
							toast.error(
								<FormattedMessage id="Something went wrong, please try again later" />,
							);
						});
				}
			});
		}
			return MySwal.fire({
				title: intl.formatMessage({ id: "Notification Update" }),
				text: intl.formatMessage({
					id: "Are you sure, you want to update it?",
				}),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: intl.formatMessage({ id: "Update" }),
				customClass: {
					confirmButton: "btn btn-snapchat border-dark border-25",
					cancelButton: "btn btn-outline-danger ml-1 border-25",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					axios({
						method: "POST",
						url:
							`${process.env[
								`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`
							]}/changeNotificationStatus`,
						data: {
							analytics_notification_flag: "on",
						},
						withCredentials: true,
					})
						.then((response) => {
							if (response.status === 200) {
								toast.success(<FormattedMessage id="Status updated" />);
								getNotificationsDetail();
							}
						})
						.catch((err) => {
							toast.error(
								<FormattedMessage id="Something went wrong, please try again later" />,
							);
						});
				}
			});
	};

	useEffect(() => {
		verifyUser()
			.then((data) => {
				if (data.data.code === "100" && data.data.email) {
					setHeaderEmail(data.data.email);
					getCompanyUserData();
					getNotificationsDetail();
				} else {
					props.history.push(ROUTES.LOGIN);
				}
			})
			.catch(() => {
				props.history.push(ROUTES.LOGIN);
			});
	}, []);

	const getCompanyUserData = () => {
		setLoading(true);
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/getCompanyUser`,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		})
			.then((response) => {
				if (response.status === 200) {
					setCompanyUserData(response.data.data);
					setDesignationArrayValue(response.data.data.designation);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const validateEmail = (email) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	};

	function randomString(length, chars) {
		let result = "";
		for (let i = length; i > 0; --i)
			result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	}

	const getNotificationsDetail = () => {
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/getNotificationDetails`,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		}).then((response) => {
			if (response.status === 200) {
				setOfferNotification(response.data.data[0].offers_notification_flag);
				setMarketingNotification(
					response.data.data[0].marketing_notification_flag,
				);
				setNetworkNotification(response.data.data[0].network_notification_flag);
				setAnalyticsNotification(
					response.data.data[0].analytics_notification_flag,
				);
			}
		});
	};

	const handleSaveUser = (id) => {
		return MySwal.fire({
			title: intl.formatMessage({ id: "Confirm Changes" }),
			text: intl.formatMessage({ id: "Are you sure, you want to update it?" }),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: intl.formatMessage({ id: "Update" }),
			customClass: {
				confirmButton: "btn btn-snapchat border-dark border-25",
				cancelButton: "btn btn-outline-danger ml-1 border-25",
			},
			buttonsStyling: false,
		}).then((result) => {
			if (result.value) {
				axios({
					method: "POST",
					url:
						`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/updateUserInfo`,
					data: {
						user_id: id,
						user_role: userRole,
						designation: designation,
					},
					withCredentials: true,
				})
					.then((response) => {
						if (response.status === 200) {
							toast.success(
								intl.formatMessage({ id: "User updated successfully" }),
							);
							getCompanyUserData();
						}
					})
					.catch((err) => {
						toast.error(
							intl.formatMessage({
								id: "Something went wrong, please try again later",
							}),
						);
					});
			}
		});
	};

	const handleRemoveUser = (id) => {
		return MySwal.fire({
			title: intl.formatMessage({ id: "Delete user" }),
			text: intl.formatMessage({
				id: "Are you sure, you want to delete user?",
			}),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: intl.formatMessage({ id: "Delete" }),
			customClass: {
				confirmButton: "btn btn-snapchat border-dark border-25",
				cancelButton: "btn btn-outline-danger ml-1 border-25",
			},
			buttonsStyling: false,
		}).then((result) => {
			if (result.value) {
				axios({
					method: "POST",
					url:
						`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/deleteCompanyUser`,
					data: {
						user_id: id,
					},
					withCredentials: true,
				})
					.then((response) => {
						if (response.status === 200) {
							toast.success(
								intl.formatMessage({ id: "User deleted successfully" }),
							);
							getCompanyUserData();
						}
					})
					.catch((err) => {
						toast.error(
							intl.formatMessage({
								id: "Something went wrong, please try again later",
							}),
						);
					});
			}
		});
	};

	const handleDesignation = (e) => {
		setDesignation(e.target.value);
	};
	const handleuserRole = (e) => {
		setUserRole(e.target.value);
	};

	const onSubmit = (data) => {
		setLoaderFlag(true);
		const email_checker = validateEmail(data.email);
		if (email_checker) {
			const user_id = randomString(
				32,
				"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
			);
			axios({
				method: "POST",
				url:
					`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/addUser`,
				data: {
					user_id: user_id,
					user_role: data.userRole,
					designation: data.designation,
					cat: Date.now,
					fname: data.firstName,
					status: "true",
					email: data.email,
					lname: data.lastName,
					phone: data.phone,
				},
				withCredentials: true,
			})
				.then((response) => {
					if (response.status === 200) {
						setLoaderFlag(false);
						toast.success(
							<FormattedMessage id="User added successfully and email sent" />,
						);
						document.getElementById("add-user-form").reset();
						props.history.push("/setting");
						setFirstTabFlag(true);
						setSecondTabFlag(false);
						setThirdTabFlag(false);
						setFourthTabFlag(false);
						setModal(false);
						window.location.reload();
					}
				})
				.catch((err) => {
					setLoaderFlag(false);
					toast.error(
						`${<FormattedMessage id="Something went wrong, please try again later" />}...`,
					);
				});
		} else {
			setLoaderFlag(false);
			toast.error(<FormattedMessage id="Please enter valid email" />);
		}
	};

	const handleAddUserForm = () => {
		setModal(true);
	};
	const handleClose = () => {
		setModal(false);
	};
	const handleIpRange = (e) => {
		if (e.target.value === "") {
			setShowError(true);
			setIpRange(e.target.value);
		} else {
			setShowError(false);
			setIpRange(e.target.value);
		}
	};
	const submitIpRange = () => {
		if (ipRange === "") {
			setShowError(true);
		} else {
			setShowError(false);
			setLoaderFlag(true);
			axios({
				method: "POST",
				url:
					`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/networkIP`,
				data: {
					network_ip_range: ipRange,
				},
				withCredentials: true,
			})
				.then((response) => {
					if (response.status === 200) {
						setLoaderFlag(false);
						toast.success(
							<FormattedMessage id="Network IP added successfully" />,
						);
					}
				})
				.catch((err) => {
					setLoaderFlag(false);
					toast.error(<FormattedMessage id={err.response.data.msg} />);
				});
		}
	};

	const handleSameCompDesgn = (e) => {
		setDesignation(e.target.value);
	};
	return (
		<div
			className="vertical-layout vertical-menu-modern  navbar-floating footer-static bg-white "
			data-open="click"
			data-menu="vertical-menu-modern"
			data-col=""
		>
			<nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow customCss">
				<Header email={headerEmail} history={props.history} />
			</nav>
			<div
				className="main-menu menu-fixed menu-light menu-accordion menu-shadow"
				style={{ borderRight: "1px solid #dad8d8" }}
				data-scroll-to-active="true"
			>
				<SideNav history={props.history} />
			</div>

			<div className="app-content content page-main-div-wrapper d-none d-lg-block">
				<div className="content-overlay" />
				<div className="header-navbar-shadow" />
				<div
					className="content-header row"
					style={{ borderBottom: "1px solid #8888881A" }}
				>
					<div className="px-1 bg-white">
						{loaderFlag && (
							<div className="loader-component">
								<img src={LoaderImage} className="image-loader-offers" />
							</div>
						)}
						<ul
							className="nav nav-pills "
							style={{
								backgroundColor: "yellow",
								borderRadius: "25px",
								height: "auto",
								alignItems: "center",
								paddingLeft: "5px",
								paddingRight: "5px",
								paddingTop: "3px",
								paddingBottom: "3px",
							}}
						>
							<li className="nav-item" onClick={() => handleFirstTab()}>
								<a
									className={
										`nav-link text-darkk ${firstTabFlag ? " active no-padding" : ""}`
									}
									id="user_details-tab"
									data-toggle="pill"
									aria-expanded="false"
								>
									<FormattedMessage id="Users" />
								</a>
							</li>
							<li className="nav-item" onClick={() => handleSecondTab()}>
								<a
									className={
										`nav-link text-darkk ${secondTabFlag ? " active no-padding" : ""}`
									}
									id="notification-tab"
									data-toggle="pill"
									aria-expanded="false"
								>
									<FormattedMessage id="My Notifications" />
								</a>
							</li>
							<li className="nav-item" onClick={() => handleFourthTab()}>
								<a
									className={
										`nav-link text-darkk ${fourthTabFlag ? " active no-padding" : ""}`
									}
									id="add-user-tab"
									data-toggle="pill"
									aria-expanded="false"
								>
									<FormattedMessage id="Network IPs" />
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="content-wrapper">
					<div className="content-body">
						<div className="row">
							<div className="col-12">
								<div className="tab-content">
									{loading ? (
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												width: "100%",
												height: "67vh",
											}}
										>
											<Spinner
												style={{
													height: "80px",
													width: "80px",
													fontSize: "32px",
												}}
											/>
										</div>
									) : (
										<div
											className={
												`tab-pane mt-2 ${firstTabFlag ? " active" : ""}`
											}
											id="user_details"
											role="tabpanel"
											aria-labelledby="user_details-tab"
											aria-expanded="false"
										>
											<div className="card" id="basic-table2">
												<div className="table-responsive">
													<table className="table">
														<thead className="card-header-bg">
															<tr>
																<th>
																	<FormattedMessage id="Name" />
																</th>
																<th>
																	<FormattedMessage id="Email" />
																</th>
																<th>
																	<FormattedMessage id="Title/Desingnation" />
																</th>
																<th>
																	<FormattedMessage id="Access" />
																</th>
																<th>
																	<div className="px-1">
																		<div
																			className="btn btn-snapchat waves-effect waves-float fs-12 waves-light mb-50 border-25 "
																			onClick={handleAddUserForm}
																		>
																			+ <FormattedMessage id="ADD USER" />
																		</div>
																	</div>
																</th>
															</tr>
														</thead>
														{companyUserData.length > 0 ? (
															<tbody>
																{companyUserData &&
																	companyUserData.length > 0 &&
																	companyUserData.map((data, index) => {
																		return (
																			<tr key={index}>
																				<td>
																					<span className="font-weight-bold fs-12 text-darkk">
																						{`${data.fname} ${data.lname}`}
																					</span>
																				</td>
																				<td>
																					<span className="font-weight-bold fs-12 text-darkk">
																						{data.email}
																					</span>
																				</td>
																				<td>
																					<input
																						className="form-control border-25"
																						type="text"
																						defaultValue={data.designation}
																						onChange={(e) =>
																							handleSameCompDesgn(e)
																						}
																					/>
																				</td>
																				<td>
																					<select
																						className="form-control border-25"
																						defaultValue={data.user_role}
																						onChange={(e) => handleuserRole(e)}
																					>
																						<FormattedMessage id="Admin">
																							{(message) => (
																								<option value="Admin">
																									{message}
																								</option>
																							)}
																						</FormattedMessage>
																					</select>
																				</td>

																				<td>
																					<div className="px-1">
																						<button
																							type="button"
																							className="btn btn-secondary waves-effect waves-float fs-12 waves-light mb-50 border-25"
																							style={{ marginRight: "8px" }}
																							onClick={() =>
																								handleSaveUser(data.user_id)
																							}
																						>
																							<FormattedMessage id="Save" />
																						</button>
																						<button
																							type="button"
																							className="btn btn-snapchat-danger waves-effect waves-float fs-12 waves-light mb-50 border-25 "
																							onClick={() =>
																								handleRemoveUser(data.user_id)
																							}
																						>
																							<FormattedMessage id="Remove User" />
																						</button>
																					</div>
																				</td>
																			</tr>
																		);
																	})}
															</tbody>
														) : (
															<tbody>
																<tr>
																	<td
																		colSpan="8"
																		className="text-center font-weight-bolder"
																		style={{ fontSize: "15px", color: "#000" }}
																	>
																		<FormattedMessage id="No Records Found" />
																	</td>
																</tr>
															</tbody>
														)}
													</table>
												</div>
											</div>
										</div>
									)}

									<div
										className={
											`tab-pane mt-2 ${secondTabFlag ? " active" : ""}`
										}
										id="notification"
										role="tabpanel"
										aria-labelledby="notification-tab"
										aria-expanded="false"
									>
										<div className="row">
											<div className="col-4 pr-0">
												<div className="rounded card">
													<div className="card-header align-items-center p-75">
														<p
															className="fs-15 mb-0 text-darkk"
															style={{ fontSize: "18px" }}
														>
															<FormattedMessage id="Offers" />
														</p>
														<div className="custom-control custom-control-success custom-switch">
															<input
																type="checkbox"
																className="custom-control-input"
																checked={
																	offerNotification  === "on"
																}
																id="offers_notifications"
																onChange={(e) => handleCheckBox(e)}
															/>
															<label
																className="custom-control-label"
																htmlFor="offers_notifications"
															/>
														</div>
														<small
															className="fs-12 mb-0 mt-2 col-10 px-0"
															style={{ marginTop: "5px", minHeight: "40px" }}
														>
															<FormattedMessage id="Receive notifications when an Offer request is approved, and at each stage of Offer launch process." />
														</small>
													</div>
												</div>
											</div>
											<div className="col-4 pr-0">
												<div className="rounded card">
													<div className="card-header align-items-center p-75">
														<p
															className="fs-15 mb-0 text-darkk"
															style={{ fontSize: "18px" }}
														>
															<FormattedMessage id="Marketing" />
														</p>
														<div className="custom-control custom-control-success custom-switch">
															<input
																type="checkbox"
																className="custom-control-input"
																checked={
																	marketingNotification  === "on"
																}
																id="marketing_notifications"
																onChange={() => handleSecondCheckbox()}
															/>
															<label
																className="custom-control-label"
																htmlFor="marketing_notifications"
															/>
														</div>
														<p
															className="fs-12 mb-0 mt-2 col-10 px-0"
															style={{ marginTop: "5px", minHeight: "40px" }}
														>
															<FormattedMessage id="Receive notifications regarding Marketing assets submitted for approval" />
														</p>
													</div>
												</div>
											</div>
											<div className="col-4 pr-0">
												<div className="rounded card">
													<div className="card-header align-items-center p-75">
														<p
															className="fs-15 mb-0 text-darkk"
															style={{ fontSize: "18px" }}
														>
															<FormattedMessage id="Technical" />
														</p>
														<div className="custom-control custom-control-success custom-switch">
															<input
																type="checkbox"
																className="custom-control-input"
																checked={
																	networkNotification  === "on"
																}
																id="network_notifications"
																onChange={() => handleThirdCheck()}
															/>
															<label
																className="custom-control-label"
																htmlFor="network_notifications"
															/>
														</div>
														<p
															className="fs-12 mb-0 mt-2 col-10 px-0"
															style={{ marginTop: "5px", minHeight: "40px" }}
														>
															<FormattedMessage id="Receive notifications each time our Domain/IP list is updated and other related messages" />
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className={
											`tab-pane mt-2 ${thirdTabFlag ? " active" : ""}`
										}
										id="carriers"
										role="tabpanel"
										aria-labelledby="carriers-tab"
										aria-expanded="false"
									/>
									<div
										className={
											`tab-pane mt-2 ${fourthTabFlag ? " active" : ""}`
										}
										id="add-user-tab"
										role="tabpanel"
										aria-labelledby="carriers-tab"
										aria-expanded="false"
									>
										<div className="card" id="basic-table3">
											<div className="card-header card-header-bg">
												<div className="col-lg-12">
													<h6 className="card-title text-darkk">
														<FormattedMessage id="IP range for the addresses assigned to your mobile subscribers" />
													</h6>
												</div>
											</div>
											<div className="card-body mt-2">
												<div className="col-lg-12">
													<p className="fs-12 text-darkk">
														<FormattedMessage id="Snapchat includes a video and voice calling feature which utilizes Peer-to-peer (P2P) data connections to improve performance. These P2P connections cannot be detected by carriers using our IP/Domains. To resolve this we disable the P2P optimization for the carrier’s mobile subscribers if you have an active data offer. The carrier must provide an IP range for the addresses assigned to mobile subscribers in their target network." />
													</p>
													<div className="row">
														<div className="col-md-6 col-12">
															<div className="form-group">
																<label htmlFor="iprange" className="mb-1">
																	<FormattedMessage id="Please enter below one IP range per line" />
																</label>
																<textarea
																	className="form-control input-color"
																	value={ipRange}
																	id="iprange"
																	name="iprange"
																	rows="4"
																	onChange={(e) => handleIpRange(e)}
																/>
																{showError && (
																	<label
																		style={{
																			color: "red",
																			fontStyle: "normal",
																		}}
																	>
																		<FormattedMessage id="This feild is required" />
																		.
																	</label>
																)}
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-12 mt-1 mb-1">
															<button
																type="submit"
																className="btn btn-snapchat mr-1 waves-effect border-25 waves-float waves-light"
																onClick={submitIpRange}
															>
																<FormattedMessage id="Save" />
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<Modal size="md" show={modal} onHide={handleClose} centered>
										{loaderFlag && (
											<div className="loader-component">
												<img
													src={LoaderImage}
													className="image-loader-offers"
												/>
											</div>
										)}
										<Modal.Header closeButton>
											<Modal.Title>
												<FormattedMessage id="Add User" />
											</Modal.Title>
										</Modal.Header>
										<Modal.Body style={{ marginTop: "10px" }}>
											<form
												className="form"
												onSubmit={handleSubmit(onSubmit)}
												id="add-user-form"
											>
												<div className="row">
													<div className="col-md-6 col-12">
														<div className="form-group">
															<label
																htmlFor="last-name-column"
																className="mb-1"
															>
																<FormattedMessage id="First Name" />*
															</label>
															<input
																type="text"
																id="firstName"
																className="form-control input-color"
																name="firstName"
																{...register("firstName", {
																	required: true,
																	pattern: /^[a-zA-Z]*$/,
																})}
															/>
															{errors?.firstName?.type === "pattern" && (
																<label
																	style={{ color: "red", fontStyle: "normal" }}
																>
																	<FormattedMessage id="Alphabetical characters only" />
																</label>
															)}
															{errors?.firstName?.type === "required" && (
																<label
																	style={{ color: "red", fontStyle: "normal" }}
																>
																	<FormattedMessage id="This feild is required" />
																	.
																</label>
															)}
														</div>
													</div>
													<div className="col-md-6 col-12">
														<div className="form-group">
															<label
																htmlFor="last-name-column"
																className="mb-1"
															>
																<FormattedMessage id="Last Name" />*
															</label>
															<input
																type="text"
																id="lastName"
																className="form-control input-color"
																name="lastName"
																{...register("lastName", {
																	required: true,
																	pattern: /^[a-zA-Z]*$/,
																})}
															/>
															{errors?.lastName?.type === "pattern" && (
																<label
																	style={{ color: "red", fontStyle: "normal" }}
																>
																	<FormattedMessage id="Alphabetical characters only" />
																</label>
															)}
															{errors?.lastName?.type === "required" && (
																<label
																	style={{ color: "red", fontStyle: "normal" }}
																>
																	<FormattedMessage id="This feild is required" />
																	.
																</label>
															)}
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12 col-12">
														<div className="form-group">
															<label htmlFor="designation" className="mb-1">
																<FormattedMessage id="Title/Desingnation" />
															</label>
															<input
																className="form-control input-color"
																id="designation"
																name="designation"
																rows="4"
																spellCheck="false"
																{...register("designation", {
																	required: true,
																	pattern: /^[a-zA-Z0-9\s]*$/,
																})}
															/>
															{errors?.designation?.type === "pattern" && (
																<label
																	style={{ color: "red", fontStyle: "normal" }}
																>
																	<FormattedMessage id="Alphanumericals characters only" />
																</label>
															)}
															{errors?.designation?.type === "required" && (
																<label
																	style={{ color: "red", fontStyle: "normal" }}
																>
																	<FormattedMessage id="This feild is required" />
																	.
																</label>
															)}
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12 col-12">
														<div className="form-group">
															<label htmlFor="city-column" className="mb-1">
																<FormattedMessage id="Email" />*
															</label>
															<input
																type="email"
																className="form-control input-color"
																id="email"
																name="email"
																rows="4"
																spellCheck="false"
																{...register("email", { required: true })}
															/>
															{errors.email && (
																<label
																	style={{ color: "red", fontStyle: "normal" }}
																>
																	<FormattedMessage id="Email is required" />.
																</label>
															)}
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12 col-12">
														<div className="form-group">
															<label htmlFor="phone" className="mb-1">
																<FormattedMessage id="Phone" />
															</label>
															<input
																type="number"
																className="form-control input-color"
																id="phone"
																name="phone"
																rows="4"
																spellCheck="false"
																{...register("phone", { required: false })}
															/>
															{errors.phone && (
																<label
																	style={{ color: "red", fontStyle: "normal" }}
																>
																	<FormattedMessage id="Phone is required" />.
																</label>
															)}
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-md-6 col-12">
														<div className="form-group">
															<label htmlFor="userRole" className="mb-1">
																<FormattedMessage id="Access" />*
															</label>
															<select
																className="form-control border-25"
																name="userRole"
																id="userRole"
																{...register("userRole", { required: true })}
															>
																<FormattedMessage id="Admin">
																	{(message) => (
																		<option value="Admin">{message}</option>
																	)}
																</FormattedMessage>
															</select>
														</div>
													</div>
												</div>

												<div className="row">
													<div
														className="col-12 mt-1 mb-1"
														style={{
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
														}}
													>
														<button
															type="submit"
															className="btn btn-snapchat mr-1 waves-effect border-25 waves-float waves-light"
														>
															<FormattedMessage id="Submit" />
														</button>
													</div>
												</div>
											</form>
										</Modal.Body>
									</Modal>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="sidenav-overlay" />
				<div className="drag-target" />
			</div>
			<div
				className="fullscreenDiv d-xl-none d-lg-none d-md-block d-sm-block d-xs-block"
				style={{ textAlign: "center" }}
			>
				<div className="center text-dark font-weight-bolder">
					<h1>
						<FormattedMessage id="Works best on Desktop" />.{" "}
					</h1>
				</div>
			</div>
		</div>
	);
};
export default SettingPage;
