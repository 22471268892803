import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import LoaderImage from "../../app-assets/images/icons/loaderIcons.gif";
import SideNav from "../../component/SideNavigator";
import Header from "../../component/TopHeader";
import verifyUser from "../Verifier";
import "./resources.css";

import { FormattedMessage, useIntl } from "react-intl";
import { Spinner } from "reactstrap";
import { ROUTES } from "../../constants/routes";

const ResourcePage = (props) => {
	const MySwal = withReactContent(Swal);
	const intl = useIntl();
	const [firstTabFlag, setFirstTabFlag] = useState(true);
	const [secondTabFlag, setSecondTabFlag] = useState(false);
	const [emailHeader, setEmail] = useState("");
	const [activeOfferData, setActiveOfferData] = useState([]);
	const [IpUpdatedTime, setIpUpdatedTime] = useState("");
	const [loaderFlag, setLoaderFlag] = useState(false);
	const [thirdTabFlag, setThirdTabFlag] = useState(false);
	const [fourthTabFlag, setFourthTabFlag] = useState(false);
	const [zeroRatingGuideContent, setZeroRatingGuideContent] = useState([]);
	const [FAQs, setFAQs] = useState();
	const [caseStudyContent, setCaseStudyContent] = useState();
	const [loading, setLoading] = useState(false);
	const handleFirstTab = () => {
		setFirstTabFlag(true);
		setSecondTabFlag(false);
		setThirdTabFlag(false);
		setFourthTabFlag(false);
	};
	const handleSecondTab = () => {
		setFirstTabFlag(false);
		setSecondTabFlag(true);
		setThirdTabFlag(false);
		setFourthTabFlag(false);
		getZeroRatingContent("Zero-Rating Guide");
	};
	const handleThirdTab = () => {
		setFirstTabFlag(false);
		setSecondTabFlag(false);
		setThirdTabFlag(true);
		setFourthTabFlag(false);
		getZeroRatingContent("Case Study");
	};
	const handleFourthTab = () => {
		setFirstTabFlag(false);
		setSecondTabFlag(false);
		setThirdTabFlag(false);
		setFourthTabFlag(true);
		getZeroRatingContent("FAQs");
	};

	useEffect(() => {
		verifyUser()
			.then((data) => {
				if (data.data.code === "100" && data.data.email) {
					setEmail(data.data.email);
					getActiveOffersData();
					getIpUpdatedTime();
					getAckUpdateTime();
				} else {
					props.history.push(ROUTES.LOGIN);
				}
			})
			.catch(() => {
				props.history.push(ROUTES.LOGIN);
			});
	}, []);

	useEffect(() => {
		const detailsOpened = document.querySelectorAll("details[open]");
		for (const item of detailsOpened) {
			if (this !== item) {
				item.removeAttribute("open");
			}
		}
	}, [FAQs]);

	const getZeroRatingContent = (sectionName) => {
		setLoading(true);
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/getCmsData?pageName=zeroRating&sectionName=${sectionName}`,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		})
			.then((response) => {
				if (sectionName === "Zero-Rating Guide") {
					setZeroRatingGuideContent(response.data.data);
				} else if (sectionName === "FAQs") {
					setFAQs(response.data.data.section_content.trim());
				} else if (sectionName === "Case Study") {
					setCaseStudyContent(response.data.data);
				}
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setLoading(false);
				const detailsOpened = document.querySelectorAll("details[open]");
				for (const item of detailsOpened) {
					// Close the details tag
					if (this !== item) {
						item.removeAttribute("open");
					}
				}
			});
	};

	const getActiveOffersData = () => {
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/getActiveOffers`,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		}).then((response) => {
			if (response.status === 200) {
				setActiveOfferData(response.data.data);
			}
		});
	};

	const getIpUpdatedTime = () => {
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/ipListUpdatedTime`,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		}).then((response) => {
			if (response.status === 200) {
				setIpUpdatedTime(response.data.UpdatedDate);
				if (document.getElementById("IPUpdated")) {
					document.getElementById("IPUpdated").innerHTML =
						`${intl.formatMessage({ id: "Last updated:" })} ${response.data.UpdatedDate}`;
				}
			}
		});
	};

	const getAckUpdateTime = () => {
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/IPListAcknowledgeUpdate`,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		}).then((response) => {
			if (response.status === 200) {
				if (response.data.msg.length > 0) {
					const strByEmail =
						`${<FormattedMessage id="by" />}:${response.data.msg.email}`;
					document.getElementById("ack_update").innerHTML =
						`${intl.formatMessage({ id: "Last update acknowledged on:" })} ${response.data.msg.acknowledge_at}${strByEmail}`;
				}
			}
		});
	};

	const handleDownloadIpList = (e) => {
		return MySwal.fire({
			title: intl.formatMessage({ id: "Download" }),
			text: intl.formatMessage({
				id: "Are you sure, you want to download files?",
			}),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: intl.formatMessage({ id: "Download" }),
			customClass: {
				confirmButton: "btn btn-snapchat border-dark border-25",
				cancelButton: "btn btn-outline-danger ml-1 border-25",
			},
			buttonsStyling: false,
		}).then((result) => {
			setLoaderFlag(true);
			if (result.value) {
				axios({
					method: "GET",
					url:
						`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/ipListDownload`,
					withCredentials: true,
				}).then((response) => {
					const fileDataArr = response.data.data;
					const link = document.createElement("a");
					const fileContents = Buffer(fileDataArr.data.Body, "base64");
					const blob = new Blob([fileContents]);
					link.href = URL.createObjectURL(blob);
					link.download = fileDataArr.name;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					setLoaderFlag(false);
					toast.success(
						<FormattedMessage id="Files downloaded successfully " />,
					);
				});
			}
		});
	};

	const handleAcknowledgeButton = (e) => {
		return MySwal.fire({
			title: intl.formatMessage({ id: "Acknowledge Update" }),
			text: intl.formatMessage({
				id: "Are you sure, your configuration has been updated with the latest list of Domains & IPs?",
			}),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: intl.formatMessage({ id: "Acknowledge" }),
			customClass: {
				confirmButton: "btn btn-snapchat border-dark border-25",
				cancelButton: "btn btn-outline-danger ml-1 border-25",
			},
			buttonsStyling: false,
		}).then((result) => {
			if (result.value) {
				axios({
					method: "POST",
					url:
						`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/IPListAcknowledgeUpdate`,
					withCredentials: true,
					data: {
						status: true,
					},
				}).then((response) => {
					toast.success(
						<FormattedMessage id="Acknowledgement submitted successfully" />,
					);
					const strByEmail =
						`${<FormattedMessage id="by" />}:${response.data.msg.email}`;
					document.getElementById("ack_update").innerHTML =
						`${intl.formatMessage({ id: "Last update acknowledged on:" })} ${response.data.msg.acknowledge_at}${strByEmail}`;
				});
			}
		});
	};

	return (
		<div
			className="vertical-layout vertical-menu-modern  navbar-floating footer-static bg-white "
			data-open="click"
			data-menu="vertical-menu-modern"
			data-col=""
		>
			{/* <!-- BEGIN: Header--> */}
			<nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow customCss text-decoration-none ">
				<Header email={emailHeader} />
			</nav>
			{/* <!-- END: Header--> */}
			{/* <!-- BEGIN: Main Menu--> */}
			<div
				className="main-menu menu-fixed menu-light menu-accordion menu-shadow"
				style={{ borderRight: "1px solid #dad8d8" }}
				data-scroll-to-active="true"
			>
				<SideNav history={props.history} />
			</div>
			{/* <!-- END: Main Menu--> */}

			{/* <!-- BEGIN: Content--> */}
			<div className="app-content content page-main-div-wrapper d-none d-lg-block">
				<div className="content-overlay" />
				<div className="header-navbar-shadow" />
				<div
					className="content-header row"
					style={{ borderBottom: "1px solid #8888881A" }}
				>
					<div className="px-1 bg-white">
						{loaderFlag && (
							<div className="loader-component">
								<img src={LoaderImage} className="image-loader-offers" />
							</div>
						)}
						{/* "background-color: yellow;border-radius: 25px;height: 48px;align-items: center; */}
						<ul
							className="nav nav-pills"
							style={{
								backgroundColor: "yellow",
								borderRadius: "25px",
								height: "auto",
								alignItems: "center",
								paddingLeft: "5px",
								paddingRight: "5px",
								paddingTop: "3px",
								paddingBottom: "3px",
							}}
						>
							<li className="nav-item" onClick={() => handleFirstTab()}>
								<a
									className={
										`nav-link text-darkk${firstTabFlag ? " active no-padding" : ""}`
									}
									id="domain_iplist-tab"
									data-toggle="pill"
									aria-expanded="true"
								>
									<FormattedMessage id="Domain & IP Lists" />
								</a>
							</li>
							<li className="nav-item" onClick={() => handleSecondTab()}>
								<a
									className={
										`nav-link text-darkk${secondTabFlag ? " active no-padding" : ""}`
									}
									id="flatrating_carrierguide-tab"
									data-toggle="pill"
									aria-expanded="false"
								>
									<FormattedMessage id="Zero-Rating Guide" />
								</a>
							</li>
							<li className="nav-item" onClick={() => handleThirdTab()}>
								<a
									className={
										`nav-link text-darkk${thirdTabFlag ? " active no-padding" : ""}`
									}
									id="flatrating_carrierguide-tab"
									data-toggle="pill"
									aria-expanded="false"
								>
									<FormattedMessage id="Case Study" />
								</a>
							</li>
							<li className="nav-item" onClick={() => handleFourthTab()}>
								<a
									className={
										`nav-link text-darkk${fourthTabFlag ? " active no-padding" : ""}`
									}
									id="flatrating_carrierguide-tab"
									data-toggle="pill"
									aria-expanded="false"
								>
									<FormattedMessage id="FAQ" />
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="content-wrapper">
					{loading ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
								height: "67vh",
							}}
						>
							<Spinner
								style={{ height: "80px", width: "80px", fontSize: "32px" }}
							/>
						</div>
					) : (
						<div className="content-body">
							<div className="row">
								<div className="col-12">
									<div className="tab-content">
										<div
											role="tabpanel"
											className={
												`tab-pane mt-2 ${firstTabFlag ? " active" : ""}`
											}
											id="domain_iplist"
											aria-labelledby="domain_iplist-tab"
											aria-expanded="true"
										>
											<div className="card ">
												<div className="card-header card-header-bg">
													<div className="col-lg-12">
														<h4 className="card-title text-darkk">
															<FormattedMessage id="Snapchat Domain & IP Lists" />
														</h4>
													</div>
												</div>
												<div className="card-body mt-2">
													<div className="col-lg-12">
														<p className="text-dark fs-12">
															{" "}
															<FormattedMessage id="We provide the 3 separate lists to our carrier partners looking to launch offers around Snapchat. Both Domains and IP addresses must be zero/flat-rated to correctly capture all of Snapchat traffic" />
														</p>
													</div>
													<div className="col-lg-12">
														<p className="text-dark fs-12 mb-0">
															{" "}
															1.{" "}
															<FormattedMessage id="Domains, if wildcards are supported" />{" "}
															<strong className="text-darkk">
																(domain_list_wildcards.csv)
															</strong>
														</p>
														<p className="text-dark fs-12 mb-0">
															{" "}
															2.{" "}
															<FormattedMessage id="Domains, if no wildcard are supported" />{" "}
															<strong className="text-darkk">
																(domain_list_no_wildcards.csv)
															</strong>
														</p>
														<p className="text-dark fs-12 ">
															{" "}
															3. <FormattedMessage id="IP address" />{" "}
															<strong className="text-darkk">
																(ip_list.csv)
															</strong>
														</p>
													</div>
													<div className="col-lg-12">
														<p
															className="text-dark fs-10 mb-0"
															id="IPUpdated"
														/>
													</div>
													<div className="col-lg-12">
														<button
															type="button"
															className="btn btn-snapchat mr-1 waves-effect border-25 waves-float waves-light mb-1"
															onClick={(e) => handleDownloadIpList(e)}
														>
															<FormattedMessage id="Download" />
														</button>
													</div>
													<div class="col-lg-12 mt-1">
														<p className="fs-12 text-darkk">Note:</p>
													</div>
													<div class="col-lg-12 pl-0">
														<ul className="text-darkk fs-12">
															<li>
																<FormattedMessage id="Neither list alone encompasses all Snapchat traffic. Both Domains and IP addresses must be zero/flat-rated" />
															</li>
															<li>
																<FormattedMessage id="Snapchat domain & IP list is only available to partners looking to launch a new offer or already have active offer(s)" />
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
										<div
											className={
												`tab-pane mt-2 ${secondTabFlag ? " active" : ""}`
											}
											id="flatrating_carrierguide"
											role="tabpanel"
											aria-labelledby="flatrating_carrierguide-tab"
											aria-expanded="false"
										>
											<div className="card ">
												<div className="card-header card-header-bg">
													<div className="col-lg-12">
														<h4 className="card-title text-darkk">
															<FormattedMessage
																id={
																	zeroRatingGuideContent?.section_title ||
																	"Snapchat Flat-Rating Carrier Guide"
																}
															/>
														</h4>
													</div>
												</div>
												<div className="card-body mt-2">
													<div className="col-lg-12">
														<div
															className="cms-content-style"
															dangerouslySetInnerHTML={{
																__html:
																	zeroRatingGuideContent?.section_content || "",
															}}
														/>
													</div>
												</div>
											</div>
										</div>
										<div
											className={
												`tab-pane mt-2 ${thirdTabFlag ? " active" : ""}`
											}
											id="flatrating_carrierguide"
											role="tabpanel"
											aria-labelledby="flatrating_carrierguide-tab"
											aria-expanded="false"
										>
											<div className="card">
												<div className="card-header card-header-bg">
													<div className="col-lg-12">
														<h4 className="card-title text-darkk">
															<FormattedMessage
																id={
																	caseStudyContent?.section_title ||
																	"Case Study"
																}
															/>
														</h4>
													</div>
												</div>
												<div className="card-body mt-2">
													<div className="col-lg-12">
														<div
															className="cms-content-style"
															dangerouslySetInnerHTML={{
																__html: caseStudyContent?.section_content || "",
															}}
														/>
													</div>
												</div>
											</div>
										</div>
										<div
											className={
												`tab-pane mt-2 ${fourthTabFlag ? " active" : ""}`
											}
											id="flatrating_carrierguide"
											role="tabpanel"
											aria-labelledby="flatrating_carrierguide-tab"
											aria-expanded="false"
										>
											<div className="card mt-2 collapse-icon accordion-icon-rotate">
												<div className="card-header card-header-bg">
													<div className="col-lg-12">
														<h4 className="card-title text-darkk">
															<FormattedMessage id="Frequently Asked Questions" />
														</h4>
													</div>
												</div>
												<div className="card-content">
													<div className="card-body">
														<div className="accordion-default collapse-bordered">
															<div
																className="card collapse-header border-0 "
																dangerouslySetInnerHTML={{ __html: FAQs }}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="sidenav-overlay" />
				<div className="drag-target" />
			</div>
			<div
				className="fullscreenDiv d-xl-none d-lg-none d-md-block d-sm-block d-xs-block"
				style={{ textAlign: "center" }}
			>
				<div className="center text-dark font-weight-bolder">
					<h1>
						<FormattedMessage id="Works best on Desktop" />.{" "}
					</h1>
				</div>
			</div>
		</div>
	);
};
export default ResourcePage;
