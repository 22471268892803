import axios from "axios";

// user authentication on load of page

export default async function onAuthenticate() {
	const URL =
		`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/verifyUser`;
	return await axios(URL, {
		method: "POST",
		data: {},
		withCredentials: true,
		headers: {
			"content-type": "application/json", // whatever you want
		},
	});
}

// export default {onAuthenticate};
