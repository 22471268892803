import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import LoaderImage from "../../app-assets/images/icons/loaderIcons.gif";
import SideNav from "../../component/SideNavigator";
import Header from "../../component/TopHeader";
import verifyUser from "../Verifier";

import { FormattedMessage } from "react-intl";
import { Spinner } from "reactstrap";
import { ROUTES } from "../../constants/routes";

const MarketingPage = (props) => {
	const [firstTabFlag, setFirstTabFlag] = useState(true);
	const [secondTabFlag, setSecondTabFlag] = useState(false);
	const [thirdTabFlag, setThirdTabFlag] = useState(false);
	const [email, setEmail] = useState("");
	const [attachment, setAttachment] = useState(null);
	const [fileData, setFileData] = useState("");
	const [fileUploadDataFlag, setFileUploadDataFlag] = useState(false);
	const [loaderFlag, setLoaderFlag] = useState(false);
	const [allMarketingAssets, setAllMarketingAssets] = useState([]);
	const [brandGuidelinesContent, setBrandGuidelinesContent] = useState();
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const handleFirstTab = () => {
		setFirstTabFlag(true);
		setSecondTabFlag(false);
		setThirdTabFlag(false);
	};
	const handleSecondTab = () => {
		setFirstTabFlag(false);
		setSecondTabFlag(true);
		setThirdTabFlag(false);
	};
	const handleThirdTab = () => {
		setFirstTabFlag(false);
		setSecondTabFlag(false);
		setThirdTabFlag(true);
		getAllMarketing();
	};

	useEffect(() => {
		setLoading(true);
		getMarketingContent();
		verifyUser()
			.then((data) => {
				if (data.data.code === "100" && data.data.email) {
					setEmail(data.data.email);
					getAllMarketing();
				} else {
					props.history.push(ROUTES.LOGIN);
				}
			})
			.catch(() => {
				props.history.push(ROUTES.LOGIN);
			});
	}, []);

	const getMarketingContent = async (sectionName) => {
		setLoading(true);
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/getCmsData?pageName=marketing&sectionName=Brand Guidelines`,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		})
			.then((response) => setBrandGuidelinesContent(response.data.data))
			.catch((err) => console.log(err))
			.finally(() => setLoading(false));
	};

	const getAllMarketing = () => {
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/getMarketingAssetsRequest`,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		}).then((response) => {
			if (response.status === 200) {
				setAllMarketingAssets(response.data.data);
			}
		});
	};
	const onSubmit = (data) => {
		setLoaderFlag(true);
		data = { ...data, attachment: attachment };
		axios({
			method: "POST",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/marketingAssets`,
			data,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		})
			.then((response) => {
				if (response.status === 200) {
					setLoaderFlag(false);
					toast.success(
						<FormattedMessage id="Marketing assets submitted successfully" />,
					);
					document.getElementById("marketingForm").reset();
				}
			})
			.catch((error) => {
				setLoaderFlag(false);
				console.log(error);
				toast.error(<FormattedMessage id={error.response.data.msg} />);
			});
	};
	const fileDataValue = register("fileisuploaded", { required: true });

	const handleFileUplaod = (event) => {
		const reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		reader.onload = () => {
			// Make a fileInfo Object
			setAttachment({
				name: event.target.files[0].name,
				type: event.target.files[0].type,
				size: `${Math.round(event.target.files[0].size / 1000)} kB`,
				base64: reader.result,
			});
		};
		setValue("fileisuploaded", event.target.files[0], {
			shouldValidate: true,
			shouldDirty: true,
		});
		setFileData(event.target.files[0]);
		if (event.target.files[0].name) {
			setFileUploadDataFlag(true);
		}
	};

	const downloadBrandGuidlinesPDF = (e) => {
		const lang = sessionStorage.getItem("lang") === "es" ? "es" : "en";
		setLoaderFlag(true);
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/downloadBrandGuidlines?lang=${lang}`,
			withCredentials: true,
		})
			.then((response) => {
				const fileContents = Buffer(response.data.data.Body, "base64");
				const blob = new Blob([fileContents]);
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				const fileName = "PartnerBrandGuidelines.pdf";
				link.download = fileName;
				link.click();
				setLoaderFlag(false);
			})
			.catch((error) => {
				setLoaderFlag(false);
				console.log(error);
				toast.error(<FormattedMessage id="Error while downloading the file" />);
			});
	};

	return (
		<div
			className="vertical-layout vertical-menu-modern  navbar-floating footer-static bg-white "
			data-open="click"
			data-menu="vertical-menu-modern"
			data-col=""
		>
			<nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow customCss">
				<Header email={email} history={props.history} />
			</nav>
			<div
				className="main-menu menu-fixed menu-light menu-accordion menu-shadow"
				style={{ borderRight: "1px solid #dad8d8" }}
				data-scroll-to-active="true"
			>
				<SideNav history={props.history} />
			</div>

			<div className="app-content content page-main-div-wrapper d-none d-lg-block">
				<div className="content-overlay" />
				<div className="header-navbar-shadow" />
				<div className="content-header row">
					<div className="px-1 bg-white">
						{loaderFlag && (
							<div className="loader-component">
								<img src={LoaderImage} className="image-loader-offers" />
							</div>
						)}
					</div>
				</div>

				<div className="content-wrapper">
					<div className="content-body">
						<div className="row">
							<div className="col-12">
								<div className="tab-content">
									{loading ? (
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												width: "100%",
												height: "67vh",
											}}
										>
											<Spinner
												style={{
													height: "80px",
													width: "80px",
													fontSize: "32px",
												}}
											/>
										</div>
									) : (
										<div
											role="tabpanel"
											className={
												`tab-pane mt-2${firstTabFlag ? " active" : ""}`
											}
											id="snap_guideline"
											aria-labelledby="snap_guideline-tab"
											aria-expanded="true"
										>
											<div className="card ">
												<div className="card-header card-header-bg">
													<div className="col-lg-12">
														<h4 className="card-title text-darkk">
															<FormattedMessage
																id={
																	brandGuidelinesContent?.section_title ||
																	"Using snap branding in your marketing"
																}
															/>
														</h4>
													</div>
												</div>
												<div className="card-body mt-2">
													<div className="col-lg-12 ">
														<p
															className="content-style"
															dangerouslySetInnerHTML={{
																__html: brandGuidelinesContent?.section_content,
															}}
														/>
													</div>
													<div className="col-12 my-2">
														<button
															type="button"
															className="btn btn-snapchat waves-effect waves-float fs-12 waves-light mb-50 border-25 mb-1 mr-1"
															onClick={downloadBrandGuidlinesPDF}
														>
															<FormattedMessage
																id={
																	brandGuidelinesContent?.section_button ||
																	"Download Brand Guidelines"
																}
															/>
														</button>
													</div>
													<div className="col-lg-12">
														<p className="fs-12 text-dark">
															{" "}
															<FormattedMessage id="Please remember to only use the official Ghost logo, available for download here." />{" "}
															<a
																href="https://storage.googleapis.com/snap-inc/brand-guidelines/snapchat-logo.zip"
																style={{
																	textDecoration: "underline",
																	color: "black",
																}}
																download
															>
																<FormattedMessage id="Download" />
															</a>
														</p>
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="sidenav-overlay" />
				<div className="drag-target" />
			</div>
			<div
				className="fullscreenDiv d-xl-none d-lg-none d-md-block d-sm-block d-xs-block"
				style={{ textAlign: "center" }}
			>
				<div className="center text-dark font-weight-bolder">
					<h1>
						<FormattedMessage id="Works best on Desktop" />.{" "}
					</h1>
				</div>
			</div>
		</div>
	);
};
export default MarketingPage;
