import React, { useEffect, useState } from "react";
import "sweetalert2/dist/sweetalert2.css";
import SideNav from "../../component/SideNavigator";
import Header from "../../component/TopHeader";
import verifyUser from "../Verifier";
import "./data&insight.css";

import axios from "axios";
import { FormattedMessage } from "react-intl";
import { Label, Spinner } from "reactstrap";
import NotFoundIconImage from "../../app-assets/images/not-found.png";
import BarChart from "../../component/BarChart";
import BubbleChart from "../../component/BubbleChart";
import ChartTables from "../../component/ChartTables";
import DoughnutChart from "../../component/DougnutChart";

import PieChart from "../../component/PieChart";
import RankTables from "../../component/RankTables";
import { ROUTES } from "../../constants/routes";
import { darkenHsla } from "../../utils/darkenHsla";

function calculateAverages(data) {
	if (data.length > 1) {
		const averages = data.map((company) => {
			let totalLatencyP50 = 0;
			let totalLatencyP90 = 0;
			let totalErrorRate = 0;
			let totalSuccessRate = 0;

			company.buckets.forEach((bucket) => {
				totalLatencyP50 += bucket.latency_p50;
				totalLatencyP90 += bucket.latency_p90;
				totalErrorRate += bucket.error_rate * 100;
				totalSuccessRate += bucket.success_rate * 100;
			});

			const numBuckets = company.buckets.length;

			return {
				companyName: company.company_name,
				avgLatencyP50: totalLatencyP50 / numBuckets,
				avgLatencyP90: totalLatencyP90 / numBuckets,
				avgErrorRate: totalErrorRate / numBuckets,
				avgSuccessRate: totalSuccessRate / numBuckets,
			};
		});
		return averages;
	}if (data) {
		let totalLatencyP50 = 0;
		let totalLatencyP90 = 0;
		let totalErrorRate = 0;
		let totalSuccessRate = 0;

		data.buckets.forEach((bucket) => {
			totalLatencyP50 += bucket.latency_p50;
			totalLatencyP90 += bucket.latency_p90;
			totalErrorRate += bucket.error_rate;
			totalSuccessRate += bucket.success_rate;
		});

		const numBuckets = data.buckets.length;

		const averages = {
			companyName: data.company_name,
			avgLatencyP50: totalLatencyP50 / numBuckets,
			avgLatencyP90: totalLatencyP90 / numBuckets,
			avgErrorRate: totalErrorRate / numBuckets,
			avgSuccessRate: totalSuccessRate / numBuckets,
		};
		return averages;
	}return [];
}

const DataInsightsPage = (props) => {
	const [firstTabFlag, setFirstTabFlag] = useState(true);
	const [secondTabFlag, setSecondTabFlag] = useState(false);
	const [emailHeader, setEmail] = useState("");
	const [loading, setLoading] = useState(true);
	const [networkPerformanceData, setNetworkPerformanceData] = useState({});
	const [engagementsData, setEngagementsData] = useState({});
	const [averages, setAverages] = useState([]);
	const [yValues, setYvalues] = useState([]);
	const [xValues, setXvalues] = useState([]);
	let minY;
	let minX;
	let maxY;
	let maxX;
	let options;
	let data;
	let engagementsOptionsBar;
	let engagementsDataBar;
	let engagementsDataPie;
	let engagementsOptionsPie;
	let engagementsDataDoughnut;
	let engagementsOptionsDoughnut;

	// Get min and max
	minX = Math.floor(Math.min(...xValues));
	maxX = Math.ceil(Math.max(...xValues));

	minY = Math.floor(Math.min(...yValues));
	maxY = Math.ceil(Math.max(...yValues));

	// Set scales
	//Bubble chart
	options = {
		title: {
			fontSize: "13",
			display: true,
		},
		scales: {
			yAxes: [
				{
					scaleLabel: {
						display: true,
						fontSize: 20,
						fontColor: "#000",
						fontStyle: "bold",
						labelString: "Latency P50 (ms)",
						lineHeight: "3",
					},
					ticks: {
						min: minY - 30,
						max: maxY + 30,
					},
				},
			],
			xAxes: [
				{
					scaleLabel: {
						display: true,
						fontSize: 20,
						fontColor: "#000",
						fontStyle: "bold",
						labelString: "Error Rate",
						lineHeight: "3",
					},
					ticks: {
						callback: (label) => `${label}%`,
						min: minX - 3,
						max: maxX + 3,
					},
				},
			],
		},
		tooltips: {
			bodyFontSize: 13,
			callbacks: {
				label: (tooltipItem, data) => {
					const label = data.datasets[tooltipItem.datasetIndex].label || "";
					const xLabel =
						data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].x;
					const yLabel =
						data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y;
					return (
						`${label.charAt(0).toUpperCase() +
						label.slice(1)} (Error Rate: ${xLabel.toFixed(2)}%, Latency P50: ${yLabel.toFixed(2)}ms)`
					);
				},
			},
		},
	};

	const colors = [
		"hsla(252, 100%, 83%, 1)",
		"hsla(324, 100%, 87%, 1)",
		"hsla(144, 100%, 83%, 1)",
		"hsla(72, 100%, 88%, 1)",
		"hsla(180, 100%, 88%, 1)",
		"hsla(288, 100%, 88%, 1)",
		"hsla(36, 100%, 83%, 1)",
		"hsla(0, 100%, 87%, 1)",
		"hsla(216, 100%, 87%, 1)",
		"hsla(108, 100%, 87%, 1)",
	];

	// Bar Chart
	engagementsOptionsBar = {
		responsive: true,
		legend: {
			display: false,
		},
		scales: {
			yAxes: [
				{
					scaleLabel: {
						display: true,
						fontSize: 18,
						fontColor: "#000",
						fontStyle: "bold",
						labelString: "Daily Active Users",
						lineHeight: "3",
					},
					ticks: {
						callback: (label) => `${label}%`,
					},
				},
			],
			xAxes: [
				{
					scaleLabel: {
						display: true,
						fontSize: 18,
						fontColor: "#000",
						fontStyle: "bold",
						labelString: "Age Category",
						lineHeight: "3",
					},
				},
			],
		},
		plugins: {
			legend: {
				position: "top",
			},
		},
		tooltips: {
			titleFontSize: 13,
			bodyFontSize: 14,
			callbacks: {
				title: (tooltipItem, data) => {
					const label = data.labels[tooltipItem[0].index];
					return `Age Group: ${label}`;
				},
				label: (tooltipItem, data) => {
					const val =
						data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
					return ` ${val.toFixed(2)}%`;
				},
			},
		},
	};

	const labels = ["13-17", "18-23", "24-29", "30-45", "46-64", "65+"];

	const datasetDataBar = engagementsData?.age_groups?.map(
		(age) => age.proportion * 100,
	);

	engagementsDataBar = {
		labels,
		datasets: [
			{
				label: " ",
				data: datasetDataBar,
				backgroundColor: "#ACD2FD",
				borderColor: "#165BAA",
				borderWidth: 1,
			},
		],
	};

	// Pie Chart
	engagementsOptionsPie = {
		responsive: true,
		legend: {
			display: true,
			position: "bottom",
			labels: {
				boxWidth: 0,
				fontSize: 16,
			},
		},
		tooltips: {
			bodyFontSize: 14,
			callbacks: {
				label: (tooltipItem, data) => {
					const val =
						data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
					return tooltipItem.index === 1
						? `Male: ${val}%`
						: `Female: ${val}%`;
				},
			},
		},
	};

	engagementsDataPie = {
		datasets: [
			{
				data: [
					(engagementsData?.gender_proportions?.female * 100).toFixed(2),
					(engagementsData?.gender_proportions?.male * 100).toFixed(2),
				],
				backgroundColor: ["#F0C2FF", "#ACD2FD"],
				borderColor: ["#8C4CA1", "#165BAA"],
				hoverBackgroundColor: ["#e080ff", "#6daffb"],
			},
		],
	};

	// Doughnut Chart
	engagementsOptionsDoughnut = {
		responsive: true,
		legend: {
			display: true,
			position: "bottom",
			labels: {
				boxWidth: 0,
				fontSize: 16,
			},
		},
		tooltips: {
			bodyFontSize: 14,
			callbacks: {
				label: (tooltipItem, data) => {
					const val =
						data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
					return tooltipItem.index === 1
						? `Android: ${val}%`
						: `IOS: ${val}%`;
				},
			},
		},
	};

	engagementsDataDoughnut = {
		datasets: [
			{
				data: [
					(engagementsData?.os_proportions?.ios * 100).toFixed(2),
					(engagementsData?.os_proportions?.android * 100).toFixed(2),
				],
				backgroundColor: ["#FFC2C2", "#BDFFCE"],
				borderColor: ["#A14C4C", "#45965B"],
				hoverBackgroundColor: ["#FF9292", "#AAEDCB"],
			},
		],
	};

	if (networkPerformanceData.currentCompany) {
		data = {
			datasets: [
				{
					label: networkPerformanceData.currentCompany.company_name,
					data: [
						{
							x:
								calculateAverages(networkPerformanceData.currentCompany)
									.avgErrorRate * 100,
							y: calculateAverages(networkPerformanceData.currentCompany)
								.avgLatencyP50,
							r: 50,
						},
					],
					backgroundColor: "hsla(216, 100%, 87%, 1)",
					borderColor: darkenHsla("hsla(216, 100%, 87%, 1)"),
					borderWidth: 2,
				},
				...averages.map((data, index) => ({
					label: data.companyName,
					data: [
						{
							x: data.avgErrorRate,
							y: data.avgLatencyP50,
							r: 50,
						},
					],
					backgroundColor: colors[(index + 1) % colors.length],
					borderColor: darkenHsla(colors[(index + 1) % colors.length]),
					borderWidth: 2,
				})),
			],
		};
	}

	const handleFirstTab = () => {
		setFirstTabFlag(true);
		setSecondTabFlag(false);
		getNetworkPerformance();
	};
	const handleSecondTab = () => {
		setFirstTabFlag(false);
		setSecondTabFlag(true);
		getEngagements();
	};

	const getNetworkPerformance = () => {
		setLoading(true);
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/getNetworkPerformance`,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		})
			.then((response) => {
				if (response.status === 200) {
					setNetworkPerformanceData(response.data.data);
				}
				console.log("Response", response.data.data);
			})
			.finally(() => setLoading(false));
	};

	const getEngagements = () => {
		setLoading(true);
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/getEngagements`,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		})
			.then((response) => {
				if (response.status === 200) {
					setEngagementsData(response.data.data.currentCompany);
				}
			})
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		verifyUser()
			.then((data) => {
				if (data.data.code === "100" && data.data.email) {
					setEmail(data.data.email);
				} else {
					props.history.push(ROUTES.LOGIN);
				}
			})
			.catch(() => {
				props.history.push(ROUTES.LOGIN);
			});
		getNetworkPerformance();
	}, []);

	useEffect(() => {
		if (networkPerformanceData.currentCompany) {
			setAverages(calculateAverages(networkPerformanceData.otherCompanies));

			// Extract all x and y values
			setYvalues(
				calculateAverages(networkPerformanceData.otherCompanies).flatMap(
					(d) => d.avgLatencyP50,
				),
			);

			setXvalues(
				calculateAverages(networkPerformanceData.otherCompanies).flatMap(
					(d) => d.avgErrorRate,
				),
			);

			setXvalues((prevState) => [
				...prevState,
				(100 *
					networkPerformanceData.currentCompany.buckets.reduce(
						(sum, b) => sum + b.error_rate,
						0,
					)) /
					networkPerformanceData.currentCompany.buckets.length,
			]);
			setYvalues((prevState) => [
				...prevState,
				networkPerformanceData.currentCompany.buckets.reduce(
					(sum, b) => sum + b.latency_p50,
					0,
				) / networkPerformanceData.currentCompany.buckets.length,
			]);
		}
	}, [networkPerformanceData]);

	return (
		<div
			className="vertical-layout vertical-menu-modern  navbar-floating footer-static bg-white "
			data-open="click"
			data-menu="vertical-menu-modern"
			data-col=""
		>
			{/* <!-- BEGIN: Header--> */}
			<nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow customCss text-decoration-none ">
				<Header email={emailHeader} />
			</nav>
			{/* <!-- END: Header--> */}
			{/* <!-- BEGIN: Main Menu--> */}
			<div
				className="main-menu menu-fixed menu-light menu-accordion menu-shadow"
				style={{ borderRight: "1px solid #dad8d8" }}
				data-scroll-to-active="true"
			>
				<SideNav history={props.history} />
			</div>
			{/* <!-- END: Main Menu--> */}

			{/* <!-- BEGIN: Content--> */}
			<div className="app-content content page-main-div-wrapper d-none d-lg-block">
				<div className="content-overlay" />
				<div className="header-navbar-shadow" />
				<div
					className="content-header row d-flex flex-column"
					style={{ borderBottom: "1px solid #8888881A", gap: "8px" }}
				>
					<div className="px-1 bg-white d-flex align-items-center">
						{/* "background-color: yellow;border-radius: 25px;height: 48px;align-items: center; */}
						<ul
							className="nav nav-pills"
							style={{
								backgroundColor: "yellow",
								borderRadius: "25px",
								height: "auto",
								alignItems: "center",
								paddingLeft: "5px",
								paddingRight: "5px",
								paddingTop: "3px",
								paddingBottom: "3px",
							}}
						>
							<li className="nav-item" onClick={() => handleFirstTab()}>
								<a
									className={
										`nav-link text-darkk${firstTabFlag ? " active no-padding" : ""}`
									}
									id="domain_iplist-tab"
									data-toggle="pill"
									aria-expanded="true"
								>
									<FormattedMessage id="Network Performance" />
								</a>
							</li>
							<li className="nav-item" onClick={() => handleSecondTab()}>
								<a
									className={
										`nav-link text-darkk${secondTabFlag ? " active no-padding" : ""}`
									}
									id="flatrating_carrierguide-tab"
									data-toggle="pill"
									aria-expanded="false"
								>
									<FormattedMessage id="Engagement" />
								</a>
							</li>
						</ul>
						{networkPerformanceData?.currentCompany?.updatedAt ? (
							<span className="ml-1" style={{ fontSize: "11px" }}>
								<b>Last Updated:</b>{" "}
								{new Date(
									networkPerformanceData?.currentCompany?.updatedAt,
								).toLocaleString("en-GB", {
									month: "long",
									year: "numeric",
									day: "numeric",
								})}
							</span>
						) : null}
					</div>
					{secondTabFlag ? (
						<span className="px-2" style={{ fontSize: "11px" }}>
							<b>Disclaimer:</b> The below information is based on WWAN data
							sourced through a 10% sample size of real user traffic for the
							last 28 day period and is not official. For Snap's official
							audience data, please use the{" "}
							<a href="https://ads.snapchat.com/">Ads Manager</a>.
						</span>
					) : (
						<span className="px-2" style={{ fontSize: "11px" }}>
							<b>Disclaimer:</b> Snapchat hosts all traffic through Google and
							Amazon, and does not own any physical infrastructure. The below
							information is based on WWAN data sourced through a 10% sample
							size of real user traffic for the last 28 day period.
						</span>
					)}
				</div>
				<div className="content-wrapper">
					{loading ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
								height: "67vh",
							}}
						>
							<Spinner
								style={{ height: "80px", width: "80px", fontSize: "32px" }}
							/>
						</div>
					) : (
						<div className="content-body">
							<div className="row">
								<div className="col-12">
									<div className="tab-content">
										<div
											role="tabpanel"
											className={
												`tab-pane mt-2 ${firstTabFlag ? " active" : ""}`
											}
											id="domain_iplist"
											aria-labelledby="domain_iplist-tab"
											aria-expanded="true"
										>
											{networkPerformanceData.currentCompany && data ? (
												<>
													<BubbleChart options={options} data={data} />
													{/* Charts */}
													<ChartTables
														title="Error Rates"
														data={networkPerformanceData}
													/>

													<ChartTables
														title="Latency P50 Success"
														data={networkPerformanceData}
													/>

													<ChartTables
														title="Latency P90 Success"
														data={networkPerformanceData}
													/>

													<ChartTables
														title="TTFB P50 - Success"
														data={networkPerformanceData}
													/>

													<ChartTables
														title="TTFB P90 - Success"
														data={networkPerformanceData}
													/>
												</>
											) : (
												<div
													className="d-flex flex-column justify-content-center align-items-center w-100 mt-2"
													style={{ height: "80vh" }}
												>
													<img
														src={NotFoundIconImage}
														alt="not found"
														style={{ width: "300px", height: "300px" }}
													/>
													<Label
														style={{ fontWeight: "700", fontSize: "20px" }}
													>
														No data found
													</Label>
												</div>
											)}
										</div>
										<div
											className={
												`tab-pane mt-2 ${secondTabFlag ? " active" : ""}`
											}
											id="flatrating_carrierguide"
											role="tabpanel"
											aria-labelledby="flatrating_carrierguide-tab"
											aria-expanded="false"
										>
											{engagementsData &&
											Object.keys(engagementsData).length > 0 ? (
												<>
													<div
														className="d-flex flex-column"
														style={{ gap: "28px" }}
													>
														<div className="d-flex" style={{ gap: "24px" }}>
															<BarChart
																className="col-8 px-0"
																options={engagementsOptionsBar}
																data={engagementsDataBar}
															/>
															<PieChart
																className="px-0"
																data={engagementsDataPie}
																options={engagementsOptionsPie}
															/>
														</div>
														<div className="d-flex" style={{ gap: "24px" }}>
															<DoughnutChart
																style={{ width: "30%" }}
																data={engagementsDataDoughnut}
																options={engagementsOptionsDoughnut}
															/>
															<RankTables
																style={{ width: "35%" }}
																data={engagementsData?.device_types}
																title="Top 5 OEMs"
															/>
															<RankTables
																className="flex-grow-1"
																data={engagementsData?.top_cities}
																title="Top 5 Cities"
															/>
														</div>
													</div>
												</>
											) : (
												<div
													className="d-flex flex-column justify-content-center align-items-center w-100 mt-2"
													style={{ height: "80vh" }}
												>
													<img
														src={NotFoundIconImage}
														alt="not found"
														style={{ width: "300px", height: "300px" }}
													/>
													<Label
														style={{ fontWeight: "700", fontSize: "20px" }}
													>
														No data found
													</Label>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="sidenav-overlay" />
				<div className="drag-target" />
			</div>
			<div
				className="fullscreenDiv d-xl-none d-lg-none d-md-block d-sm-block d-xs-block"
				style={{ textAlign: "center" }}
			>
				<div className="center text-dark font-weight-bolder">
					<h1>
						<FormattedMessage id="Works best on Desktop" />.{" "}
					</h1>
				</div>
			</div>
		</div>
	);
};
export default DataInsightsPage;
