import React from "react";
import "./fourth.css";
import "../../app-assets/css/core/menu/menu-types/horizontal-menu.css";
import "../../app-assets/vendors/css/extensions/toastr.css";
import "../../app-assets/css/plugins/extensions/toastr.css";
import "../../app-assets/css/plugins/forms/form-validation.css";
import "../../app-assets/css/pages/snapchat-auth.css";
import SnapLogoWhite from "../../app-assets/images/icons/PRIMARY - GHOST_white.svg";

const FourthPage = () => {
	return (
		<div
			className="horizontal-layout horizontal-menu blank-page navbar-floating bg-white footer-fixed"
			data-open="hover"
			data-menu="horizontal-menu"
			data-col="blank-page"
		>
			<nav
				className="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center  navbar-brand-center"
				data-nav="brand-center"
			>
				<div className="navbar-header d-xl-block d-none" />
				<div className="navbar-container d-flex content">
					<div className="bookmark-wrapper d-flex align-items-center">
						<ul className="nav navbar-nav d-xl-none">
							<li className="nav-item">
								<a className="nav-link menu-toggle">
									<i className="ficon" data-feather="menu" />
								</a>
							</li>
						</ul>
						<ul className="nav navbar-nav">
							<li className="nav-item d-none d-lg-block">
								<a className="nav-link text-darkk">
									{" "}
									<img
										alt={SnapLogoWhite}
										src={SnapLogoWhite}
										style={{ width: "29px", marginRight: "10px" }}
									/>{" "}
									Mobile Partner Gateway
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>

			<div className="app-content content ">
				<div className="content-overlay" />
				<div className="header-navbar-shadow" />
				<div className="content-wrapper">
					<div className="content-header row" />
					<div className="content-body back-img">
						<div className="auth-wrapper auth-v1 px-2">
							<div className="auth-inner py-2">
								<div className="card mb-0" style={{ borderRadius: "15px" }}>
									<div className="card-body">
										<div className="col-lg-12 text-center pt-1">
											<img
												src="../../../app-assets/images/icons/PRIMARY - GHOST_white.svg"
												style={{ width: "35px" }}
											/>
										</div>
										<h1 className="card-title mb-1 text-center pt-1 font-weight-bolder">
											Setup Your Password
										</h1>
										{/* <!-- <p class="card-text mb-0">&nbsp;</p> --> */}
										<form
											className="forgot-password-form mt-2 mb-2"
											action="index.html"
											method="POST"
										>
											<div className="form-group">
												<label htmlFor="emailid" className="form-label">
													Company Email Address
												</label>
												<input
													type="text"
													class="form-control input-color"
													id="emailid"
													name="emailid"
													placeholder="companyemail@gmail.com"
													aria-describedby="login-email"
													tabIndex="0"
												/>
											</div>
											<div className="form-group">
												<div className="d-flex justify-content-between">
													<label htmlFor="login-password">Password</label>
												</div>
												<div className="input-group input-group-merge form-password-toggle">
													<input
														type="password"
														className="form-control form-control-merge input-color"
														id="password"
														name="password"
														tabIndex="0"
														placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
														aria-describedby="password"
													/>
													<div className="input-group-append">
														<span className="input-group-text cursor-pointer input-color">
															<i data-feather="eye" />
														</span>
													</div>
												</div>
											</div>

											<div className="form-group">
												<div className="d-flex justify-content-between">
													<label htmlFor="login-password">
														Confirm Password
													</label>
												</div>
												<div className="input-group input-group-merge form-password-toggle">
													<input
														type="password"
														className="form-control form-control-merge input-color"
														id="confirm-Password"
														name="confirm-Password"
														tabIndex="0"
														placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
														aria-describedby="confirm-Password"
													/>
													<div className="input-group-append input-color">
														<span className="input-group-text cursor-pointer input-color">
															<i data-feather="eye" />
														</span>
													</div>
												</div>
											</div>
											<div className="col-md-12 col-12 px-1">
												<div className="form-group">
													<div
														className=" fs-10 text-center text-darkk font-weight-bold"
														style={{ lineHeight: "1.1rem" }}
													>
														<p>
															By tapping Sign Up & Accept, you acknowledge that
															you have read the{" "}
															<a href="/privacy" class="text-snapchat-blue">
																Privacy Policy{" "}
															</a>{" "}
															and agree to the{" "}
															<a href="/privacy" class="text-snapchat-blue">
																Terms of Service.
															</a>
														</p>
													</div>
												</div>
											</div>
											<div className="col-md-12 col-12">
												<div className="form-group text-center">
													<button
														className="btn btn-snapchat border-25"
														type="button"
													>
														Accept and Continue
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<footer className="footer footer-fixed footer-light no-shadow">
				<p className="clearfix mb-0">
					<span className="float-md-left d-block d-md-inline-block mt-25">
						<a
							className="mr-1 fs-12 text-darkk"
							href="https://1.envato.market/pixinvent_portfolio"
							target="_blank" rel="noreferrer"
						>
							Term and Condition
						</a>
						<span className="d-none d-sm-inline-block">
							<a
								className="mr-50 fs-12 text-darkk"
								href="https://1.envato.market/pixinvent_portfolio"
								target="_blank" rel="noreferrer"
							>
								Privacy Policy
							</a>
						</span>
					</span>
				</p>
			</footer>
		</div>
	);
};
export default FourthPage;
