"use client";

import { useEffect, useState } from "react";

import {
	useHistory,
	useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import PaginationNumbers from "./pagination-numbers";

export default function TablePagination({
	pageCount = 1,
	pageSize,
	showSelectedCount = false,
	itemCount = 0,
}) {
	const history = useHistory();
	const location = useLocation();
	const [searchParams, setSearchParams] = useState(
		new URLSearchParams(window.location.search),
	);

	let paramsPageNumber = Number.parseInt(searchParams.get("page") || "1");

	if (paramsPageNumber > pageCount) {
		paramsPageNumber = pageCount;
	} else if (paramsPageNumber < 1) {
		paramsPageNumber = 1;
	}

	const [currentPage, setCurrentPage] = useState(paramsPageNumber);

	useEffect(() => {
		if (searchParams.has("page")) {
			searchParams.set("page", currentPage);
		} else {
			searchParams.append("page", currentPage);
		}

		setSearchParams(searchParams);
		history.replace({
			pathname: location.pathname,
			search: searchParams.toString(),
		});
	}, [currentPage, setSearchParams]);

	return (
		<PaginationNumbers
			currentPage={currentPage}
			pageSize={pageSize || 10}
			totalCount={itemCount}
			onPageChange={(page) => {
				setCurrentPage(page);
			}}
		/>
	);
}
