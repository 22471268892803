import axios from "axios";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import PrimaryGhostWhite from "../app-assets/images/icons/PRIMARY - GHOST_white.svg";
import { ROUTES } from "../constants/routes";
import "./header.css";
const TopHeader = (props) => {
	const [toggle, setToggle] = useState(false);
	const history = useHistory();
	const toggleLogout = () => {
		setToggle(!toggle);
	};
	const handleLogout = () => {
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/logout`,
			withCredentials: true,
			headers: {
				"Access-Control-Allow-Origin": "*",
				Accept: "application/json",
			},
		})
			.then((response) => {
				if (response.data.code === "100") {
					history.push(ROUTES.LOGIN);
					setToggle(false);
				}
			})
			.catch((err) => {
				toast.error("not able logout");
			});
	};
	return (
		<div className="navbar-container d-flex content">
			<ul className="nav navbar-nav d-xl-none d-lg-none d-md-block d-sm-block d-xs-block">
				<li className="nav-item ">
					<Link
						to="/home"
						className="nav-link text-white"
						style={{ fontSize: "13px" }}
					>
						<img
							alt={PrimaryGhostWhite}
							src={PrimaryGhostWhite}
							style={{ width: "29px", marginRight: "10px" }}
						/>
						<FormattedMessage id="Mobile Partner Gateway" />
					</Link>
				</li>
			</ul>
			<div className="bookmark-wrapper d-flex align-items-center">
				<ul className="nav navbar-nav d-xl-none">
					<li className="nav-item">
						<a className="nav-link menu-toggle">
							<i className="ficon" data-feather="menu" />
						</a>
					</li>
				</ul>
				<ul className="nav navbar-nav">
					<li className="nav-item d-none d-lg-block">
						<Link
							to="/home"
							className="nav-link text-white"
							style={{ fontSize: "14px", fontWeight: 500 }}
						>
							<img
								alt={PrimaryGhostWhite}
								src={PrimaryGhostWhite}
								style={{ width: "29px", marginRight: "10px" }}
							/>
							<FormattedMessage id="Mobile Partner Gateway" />
						</Link>
					</li>
				</ul>
			</div>
			<ul className="nav navbar-nav align-items-center ml-auto">
				<li className="nav-item dropdown dropdown-user">
					<a className="nav-link dropdown-user-link" id="dropdown-user">
						<div className="user-nav d-sm-flex d-none custom-drp-css-wrapper">
							{props?.email && (
								<>
									<div
										onClick={toggleLogout}
										style={{
											border: "1px solid #DBD8D8",
											borderRadius: "15px",
											color: "#DBD8D8",
											padding: "5px 15px",
										}}
									>
										{props.email}
										{toggle ? (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											>
												<polyline points="18 15 12 9 6 15" />
											</svg>
										) : (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											>
												<polyline points="6 9 12 15 18 9" />
											</svg>
										)}
									</div>
								</>
							)}
							{toggle && (
								<div className="user-info-drp" style={{ width: "240px" }}>
									<div onClick={handleLogout}>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 24 24"
											fill="none"
											stroke="currentColor"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										>
											<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
											<polyline points="16 17 21 12 16 7" />
											<line x1="21" y1="12" x2="9" y2="12" />
										</svg>
										<span
											style={{
												margin: "0px 0px 0px 5px",
												fontSize: "14px",
												fontWeight: 500,
												letterSpacing: "0.5px",
											}}
										>
											<FormattedMessage id="Logout" />
										</span>
									</div>
								</div>
							)}
						</div>
					</a>
				</li>
			</ul>
		</div>
	);
};
export default TopHeader;
