export function darkenHsla(hslaStr, amount = 50) {
	if (typeof hslaStr !== "string") {
		return;
	}

	// Check if hslaStr is transparent
	if (hslaStr === "transparent") {
		return hslaStr;
	}

	// Extract HSLA values
	const [hue, saturation, lightness, alpha] = hslaStr.match(/\d+/g).map(Number);

	// Calculate new lightness
	const newLightness = lightness - lightness * (amount / 100);

	// Construct and return new hsla string
	return `hsla(${hue}, ${saturation}%, ${newLightness}%, ${alpha})`;
}
