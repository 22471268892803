import { Card, CardBody, CardTitle } from "reactstrap";

const RankTables = ({ title, data, className, style }) => {
	let tableRowTitle = "";
	if (title === "Top 5 OEMs") {
		tableRowTitle = "DEVICE";
	} else if (title === "Top 5 Cities") {
		tableRowTitle = "CITY";
	}

	return (
		<div className={`${className}`} style={{ ...style }}>
			<Card
				style={{
					borderRadius: "16px",
					border: "1px solid #CCCCCC",
					width: "100%",
					boxShadow: "0px 4px 0px 0px #CCCCCC",
				}}
			>
				<CardTitle
					className="p-2 card-header-bg m-0"
					style={{
						borderBottom: "1px solid #CCCCCC",
						color: "black",
						fontSize: "18px",
						borderTopLeftRadius: "16px",
						borderTopRightRadius: "16px",
					}}
				>
					{title}
				</CardTitle>
				<CardBody className="p-0 w-100">
					<table className="w-100">
						<thead>
							<tr className="border-bottom">
								<th
									style={{
										fontSize: "13px",
										letterSpacing: "0.5px",
										background: "#F3F2F7",
										paddingTop: "8px",
										paddingBottom: "8px",
										paddingLeft: "16px",
									}}
								>
									RANK
								</th>
								<th
									style={{
										fontSize: "13px",
										letterSpacing: "0.5px",
										background: "#F3F2F7",
										paddingTop: "8px",
										paddingBottom: "8px",
									}}
								>
									{tableRowTitle}
								</th>
							</tr>
						</thead>
						<tbody>
							{data?.map((record) => (
								<tr className="border-bottom">
									<td className="py-2 pl-2" style={{ color: "black" }}>
										{title === "Top 5 OEMs"
											? record?.device_rank
											: title === "Top 5 Cities"
												? record?.rank
												: null}
									</td>
									<td className="py-2" style={{ color: "black" }}>
										{title === "Top 5 OEMs"
											? record?.device_name
											: title === "Top 5 Cities"
												? record?.name
												: null}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</CardBody>
			</Card>
		</div>
	);
};

export default RankTables;
