import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner, UncontrolledTooltip } from "reactstrap";
import LoaderImage from "../../app-assets/images/icons/loaderIcons.gif";
import SideNav from "../../component/SideNavigator";
import Header from "../../component/TopHeader";
import verifyUser from "../Verifier";
import "./device.css";

import { FormattedMessage } from "react-intl";
import { ROUTES } from "../../constants/routes";

export default function DevicePage(props) {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const [firstTabFlag, setFirstTabFlag] = useState(true);
	const [secondTabFlag, setSecondTabFlag] = useState(false);
	const [closeModal, setCloseModal] = useState(false);
	const [preloadsData, setPreloadsData] = useState([]);
	const [loaderFlag, setLoaderFlag] = useState(false);
	const [activepreloadRecords, setActivePreloadsRecords] = useState([]);
	const [appPreloadsContent, setAppPreloadsContent] = useState();
	const [loading, setLoading] = useState(false);

	const onSubmit = (data) => {
		setLoaderFlag(true);
		axios({
			method: "POST",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/snapAppDistribution`,
			data: {
				estimated_volume: data.estimatedVolumne,
				country: data.countryForDistribution,
				point_of_contact: data.pointOfContact,
				description: data.Description,
				types_of_distribution: data.typeOffer,
				apps: data.apps,
				placement: data.Placement,
				measurementSolution: data.measurementSolution,
				oemBrand: data.oemBrand,
			},
			withCredentials: true,
		})
			.then((response) => {
				if (response.status === 200) {
					setLoaderFlag(false);
					toast.success(<FormattedMessage id="success" />);
					setCloseModal(false);
					document.getElementById("preload-form").reset();
				}
			})
			.catch((err) => {
				setLoaderFlag(false);
				toast.error(
					<FormattedMessage id="Something went wrong, please try again later" />,
				);
			});
	};

	const [email, setEmail] = useState("");

	const handleFirstTab = () => {
		setFirstTabFlag(true);
		setSecondTabFlag(false);
	};

	const handleSecondTab = () => {
		setSecondTabFlag(true);
		setFirstTabFlag(false);
		getAllPreloads();
	};

	useEffect(() => {
		getDeviceContent();
		verifyUser()
			.then((data) => {
				if (data.data.code === "100" && data.data.email) {
					setEmail(data.data.email);
					getAllPreloads();
				} else {
					props.history.push(ROUTES.LOGIN);
				}
			})
			.catch(() => {
				props.history.push(ROUTES.LOGIN);
			});
	}, []);

	const getDeviceContent = async (sectionName) => {
		setLoading(true);
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/getCmsData?pageName=devices&sectionName=App Preloads`,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		})
			.then((response) => setAppPreloadsContent(response.data.data))
			.catch((err) => console.log(err))
			.finally(() => setLoading(false));
	};

	const getAllPreloads = () => {
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/getPreloadsRequest`,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		}).then((response) => {
			if (response.status === 200) {
				setPreloadsData(response.data.data);
				const activePreloads = response.data.data.map(
					(activePreloadData, index) => {
						if (activePreloadData.status === "Accepted") {
							return activePreloadData;
						}
					},
				);
				const finalActivePreloads = activePreloads.filter((element) => element !== undefined);

				setActivePreloadsRecords(finalActivePreloads);
			}
		});
	};
	const downloadApkFile = (e) => {
		if (activepreloadRecords.length > 0) {
			axios({
				method: "GET",
				url:
					`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/apkDownload`,
				withCredentials: true,
			}).then((response) => {
				const fileContents = Buffer(response.data.data.Body, "base64");

				const blob = new Blob([fileContents]);
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				const fileName = "SnapApp.apk";
				link.download = fileName;
				link.click();
			});
		} else {
			toast.error(
				<FormattedMessage id="Apk only available to partners with active preloads" />,
			);
		}
	};
	const handleModal = () => {
		setCloseModal(true);
	};
	const handleClose = () => {
		setCloseModal(false);
	};
	return (
		<div>
			<div
				className="vertical-layout vertical-menu-modern  navbar-floating footer-static bg-white "
				data-open="click"
				data-menu="vertical-menu-modern"
				data-col=""
			>
				<nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow customCss">
					<Header email={email} history={props.history} />
				</nav>
				<div
					className="main-menu menu-fixed menu-light menu-accordion menu-shadow"
					style={{ borderRight: "1px solid #dad8d8" }}
					data-scroll-to-active="true"
				>
					<SideNav history={props.history} />
				</div>
			</div>
			<div className="app-content content page-main-div-wrapper d-none d-lg-block">
				<div className="content-overlay" />
				<div className="header-navbar-shadow" />
				<div
					className="content-header row"
					style={{ borderBottom: "1px solid #8888881A" }}
				>
					{/* remove "bg-white" from class name */}

					<div className="px-1">
						<ul
							className="nav nav-pills "
							style={{
								backgroundColor: "yellow",
								borderRadius: "25px",
								height: "auto",
								alignItems: "center",
								paddingLeft: "5px",
								paddingRight: "5px",
								paddingTop: "3px",
								paddingBottom: "3px",
							}}
						>
							<li className="nav-item" onClick={() => handleFirstTab()}>
								<a
									className={
										`nav-link text-darkk ${firstTabFlag ? " active no-padding" : ""}`
									}
									id="preload_program-tab"
									data-toggle="pill"
									aria-expanded="true"
								>
									<FormattedMessage id="App Preloads" />
								</a>
							</li>
							<li className="nav-item" onClick={() => handleSecondTab()}>
								<a
									className={
										`nav-link text-darkk${secondTabFlag ? " active no-padding" : ""}`
									}
									id="preload_request-tab"
									data-toggle="pill"
									aria-expanded="false"
								>
									<FormattedMessage id="Request History" />
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="content-wrapper">
					<div className="content-body">
						<div className="row">
							<div className="col-12">
								<div className="tab-content">
									{loading ? (
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												width: "100%",
												height: "67vh",
											}}
										>
											<Spinner
												style={{
													height: "80px",
													width: "80px",
													fontSize: "32px",
												}}
											/>
										</div>
									) : (
										<div
											role="tabpanel"
											className={
												`tab-pane mt-2 ${firstTabFlag ? " active" : ""}`
											}
											id="preload_program"
											aria-labelledby="preload_program-tab"
											aria-expanded="true"
										>
											<div className="card ">
												<div className="card-header card-header-bg">
													<div className="col-lg-12">
														<h4 className="card-title text-darkk">
															<FormattedMessage id="App Preloads" />
														</h4>
													</div>
												</div>
												<div className="card-body mt-2">
													<div className="col-lg-12">
														<div
															className="text-darkk"
															style={{ fontSize: "16px" }}
														>
															<FormattedMessage
																id={
																	appPreloadsContent?.section_title ||
																	"Supported Devices"
																}
															/>
														</div>
														{appPreloadsContent?.section_content.length && (
															<div
																style={{
																	marginBottom: "5px",
																	marginTop: "5px",
																}}
																className="cms-content-styling"
																dangerouslySetInnerHTML={{
																	__html: appPreloadsContent.section_content,
																}}
															/>
														)}
													</div>
													<div className="px-1 py-1">
														<button
															type="button"
															className="btn btn-snapchat waves-effect waves-float fs-12 waves-light mb-50 border-25 mb-1 mr-1"
															onClick={handleModal}
														>
															{" "}
															<FormattedMessage id="Request Snap Apps for Preload" />
														</button>
														<button
															type="button"
															className="btn btn-snapchat waves-effect waves-float fs-12 waves-light mb-50 border-25 mb-1 mr-1"
															onClick={downloadApkFile}
														>
															<FormattedMessage id="Download APK" />
														</button>
													</div>
												</div>
											</div>
										</div>
									)}
									<div
										className={
											`tab-pane mt-2 ${secondTabFlag ? " active" : ""}`
										}
										id="preload_request"
										role="tabpanel"
										aria-labelledby="preload_request-tab"
										aria-expanded="false"
									>
										<div className="card" id="basic-table3">
											<div className="table-responsive">
												<table className="table">
													<thead className="card-header-bg">
														<tr>
															<th>
																<FormattedMessage id="Distribution" />
															</th>
															<th>
																<FormattedMessage id="Apps" />
															</th>
															<th>
																<FormattedMessage id="Description" />
															</th>
															<th>
																<FormattedMessage id="Estimated Volume" />
															</th>
															<th>
																<FormattedMessage id="Status" />
															</th>
														</tr>
													</thead>
													{preloadsData.length > 0 ? (
														<tbody>
															{preloadsData &&
																preloadsData.length > 0 &&
																preloadsData.map((data, index) => {
																	return (
																		<tr key={index}>
																			<td>
																				<span className="font-weight-bold fs-12 text-darkk">
																					{data.types_of_distribution}
																				</span>
																			</td>
																			<td>
																				<span className="font-weight-bold fs-12 text-darkk">
																					{data &&
																						data.apps +
																							(data.apps - 1 ? "" : "  ")}
																				</span>
																			</td>
																			<td>
																				<span className="font-weight-bold fs-12 text-darkk">
																					{data.description}
																				</span>
																			</td>
																			<td>
																				<span className="font-weight-bold fs-12 text-darkk">
																					{data.estimated_volume}
																				</span>
																			</td>
																			<td>
																				<span className="font-weight-bold fs-12 text-darkk">
																					{data.status}
																				</span>
																			</td>
																		</tr>
																	);
																})}
														</tbody>
													) : (
														<tbody>
															<tr>
																<td
																	colSpan="8"
																	className="text-center font-weight-bolder"
																	style={{ fontSize: "15px", color: "#000" }}
																>
																	<FormattedMessage id="No Records Found" />
																</td>
															</tr>
														</tbody>
													)}
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal size="lg" show={closeModal} onHide={handleClose} centered>
					{loaderFlag && (
						<div className="loader-component">
							<img src={LoaderImage} className="image-loader-offers" />
						</div>
					)}
					<Modal.Header closeButton>
						<Modal.Title>
							<FormattedMessage id="Request App(s) for Distribution" />
						</Modal.Title>
					</Modal.Header>
					<Modal.Body style={{ marginTop: "10px" }}>
						<form
							className="form"
							onSubmit={handleSubmit(onSubmit)}
							id="preload-form"
						>
							<div className="row">
								<div className="col-md-6 col-12">
									<div className="form-group">
										<label className="d-block mb-1" style={{ color: "#000" }}>
											<FormattedMessage id="Type of Distribution" />{" "}
											<svg
												xmlns="http://www.w3.org/2000/svg"
												id="typesOfDistributionTooltip"
												style={{ marginLeft: "2px", cursor: "pointer" }}
												width="15"
												height="15"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<circle cx="12" cy="12" r="10" />
												<line x1="12" y1="16" x2="12" y2="12" />
												<line x1="12" y1="8" x2="12.01" y2="8" />
											</svg>
											<UncontrolledTooltip
												placement="top"
												target="typesOfDistributionTooltip"
											>
												<FormattedMessage id="Select how you plan to distribute Snap’s application(s)." />
											</UncontrolledTooltip>{" "}
										</label>
										<div className="distribution-radio-main-wrapper">
											<div className="custom-control custom-radio my-50 d-flex align-items-center">
												<input
													type="radio"
													id="validationRadio3"
													value="Preload"
													name="typeOffer"
													className="custom-control-input"
													required=""
													{...register("typeOffer", { required: true })}
												/>
												<label
													className="custom-control-label fs-10"
													htmlFor="validationRadio3"
													style={{ color: "#000" }}
												>
													<FormattedMessage id="Preload" />
												</label>
											</div>
											<div className="custom-control custom-radio my-50 d-flex align-items-center">
												<input
													type="radio"
													value="App Store"
													id="validationRadio4"
													name="typeOffer"
													className="custom-control-input"
													required=""
													{...register("typeOffer", { required: true })}
												/>
												<label
													className="custom-control-label fs-10"
													htmlFor="validationRadio4"
													style={{ color: "#000" }}
												>
													<FormattedMessage id="App Store" />
												</label>
											</div>
											<div className="custom-control custom-radio my-50 d-flex align-items-center">
												<input
													type="radio"
													id="validationRadio5"
													value="Other Distribution"
													name="typeOffer"
													className="custom-control-input"
													required=""
													{...register("typeOffer", { required: true })}
												/>
												<label
													className="custom-control-label fs-10"
													htmlFor="validationRadio5"
													style={{ color: "#000" }}
												>
													<FormattedMessage id="Other Distribution" />
												</label>
											</div>
										</div>
										{errors.typeOffer && (
											<label style={{ color: "red", fontStyle: "normal" }}>
												<FormattedMessage id="This feild is required" />.
											</label>
										)}
									</div>
								</div>
								<div className="col-md-6 col-12">
									<div className="form-group">
										<label className="d-block mb-1" style={{ color: "#000" }}>
											<FormattedMessage id="Apps" />:{" "}
											<svg
												xmlns="http://www.w3.org/2000/svg"
												id="appsTooltip"
												style={{ marginLeft: "2px", cursor: "pointer" }}
												width="15"
												height="15"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<circle cx="12" cy="12" r="10" />
												<line x1="12" y1="16" x2="12" y2="12" />
												<line x1="12" y1="8" x2="12.01" y2="8" />
											</svg>
											<UncontrolledTooltip placement="top" target="appsTooltip">
												<FormattedMessage id="Select one or more apps you are requesting to distribute." />
											</UncontrolledTooltip>
										</label>
										<div className="distribution-checkbox-apps-main-wrapper">
											<div className="custom-control custom-checkbox my-50 custom-control-snapchat d-flex align-items-center">
												<input
													type="checkbox"
													className="custom-control-input"
													id="customCheck1"
													name="apps"
													value="Snapchat"
													{...register("apps", { required: true })}
												/>
												<label
													className="custom-control-label fs-10"
													htmlFor="customCheck1"
													style={{ color: "#000" }}
												>
													<FormattedMessage id="Snapchat" />
												</label>
											</div>
											<div className="custom-control custom-checkbox my-50 custom-control-snapchat d-flex align-items-center">
												<input
													type="checkbox"
													className="custom-control-input"
													name="apps"
													id="customCheck2"
													value="Bitmoji"
													{...register("apps", { required: true })}
												/>
												<label
													className="custom-control-label fs-10"
													htmlFor="customCheck2"
													style={{ color: "#000" }}
												>
													<FormattedMessage id="Bitmoji" />
												</label>
											</div>
											<div className="custom-control custom-checkbox my-50 custom-control-snapchat d-flex align-items-center">
												<input
													type="checkbox"
													className="custom-control-input"
													name="apps"
													id="customCheck3"
													value="Zenly"
													{...register("apps", { required: true })}
												/>
												<label
													className="custom-control-label fs-10"
													htmlFor="customCheck3"
													style={{ color: "#000" }}
												>
													<FormattedMessage id="Zenly" />
												</label>
											</div>
										</div>
										{errors.apps && (
											<label style={{ color: "red", fontStyle: "normal" }}>
												<FormattedMessage id="This feild is required" />.
											</label>
										)}
									</div>
								</div>
								<div className="col-md-6 col-12">
									<div className="form-group">
										<label
											htmlFor="city-column"
											className="mb-1"
											style={{ color: "#000" }}
										>
											<FormattedMessage id="Description" />{" "}
											<svg
												xmlns="http://www.w3.org/2000/svg"
												id="DescriptionTooltip"
												style={{ marginLeft: "2px", cursor: "pointer" }}
												width="15"
												height="15"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<circle cx="12" cy="12" r="10" />
												<line x1="12" y1="16" x2="12" y2="12" />
												<line x1="12" y1="8" x2="12.01" y2="8" />
											</svg>
											<UncontrolledTooltip
												placement="top"
												target="DescriptionTooltip"
											>
												<FormattedMessage id="Provide details here on the distribution request. For preload, also include device technical specifications (e.g. RAM/ROM requirements)." />
											</UncontrolledTooltip>
										</label>
										<input
											className="form-control input-color"
											id="validationBioBootstrap"
											name="Description"
											rows="4"
											spellCheck="false"
											{...register("Description", {
												required: true,
												pattern: /^[a-zA-Z0-9\s]*$/,
											})}
										/>
										{errors?.Description?.type === "pattern" && (
											<label style={{ color: "red", fontStyle: "normal" }}>
												<FormattedMessage id="Alphabetical characters only" />
											</label>
										)}
										{errors?.Description?.type === "required" && (
											<label style={{ color: "red", fontStyle: "normal" }}>
												<FormattedMessage id="This feild is required" />.
											</label>
										)}
									</div>
								</div>
								<div className="col-md-6 col-12">
									<div className="form-group">
										<label
											htmlFor="first-name-column"
											className="mb-1"
											style={{ color: "#000" }}
										>
											<FormattedMessage id="Estimated Volume" />{" "}
											<svg
												xmlns="http://www.w3.org/2000/svg"
												id="estimatedVolumneTooltip"
												style={{ marginLeft: "2px", cursor: "pointer" }}
												width="15"
												height="15"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<circle cx="12" cy="12" r="10" />
												<line x1="12" y1="16" x2="12" y2="12" />
												<line x1="12" y1="8" x2="12.01" y2="8" />
											</svg>
											<UncontrolledTooltip
												placement="top"
												target="estimatedVolumneTooltip"
											>
												<FormattedMessage id="Estimated shipments for device preloads or downloads for app store distribution. Also please specific over what period (e.g. first year or monthly)." />
											</UncontrolledTooltip>
										</label>
										<input
											type="text"
											id="estimatedVolumne"
											className="form-control input-color"
											name="estimatedVolumne"
											{...register("estimatedVolumne", {
												required: true,
												pattern: /^[a-zA-Z0-9\s]*$/,
											})}
										/>
										{errors?.estimatedVolumne?.type === "pattern" && (
											<label style={{ color: "red", fontStyle: "normal" }}>
												<FormattedMessage id="Alphabetical characters only" />
											</label>
										)}
										{errors?.estimatedVolumne?.type === "required" && (
											<label style={{ color: "red", fontStyle: "normal" }}>
												<FormattedMessage id="This feild is required" />.
											</label>
										)}
									</div>
								</div>

								<div className="col-md-6 col-12">
									<div className="form-group">
										<label
											htmlFor="first-name-column"
											className="mb-1"
											style={{ color: "#000" }}
										>
											Placement
											<svg
												xmlns="http://www.w3.org/2000/svg"
												id="PlacementTooltip"
												style={{ marginLeft: "2px", cursor: "pointer" }}
												width="15"
												height="15"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<circle cx="12" cy="12" r="10" />
												<line x1="12" y1="16" x2="12" y2="12" />
												<line x1="12" y1="8" x2="12.01" y2="8" />
											</svg>
											<UncontrolledTooltip
												placement="top"
												target="PlacementTooltip"
											>
												<FormattedMessage id="What placement will be provided? E.g. home +1 screen, DL folder, app store feature, etc)." />
											</UncontrolledTooltip>
										</label>
										<input
											type="text"
											id="Placement"
											className="form-control input-color"
											name="Placement"
											{...register("Placement", {
												required: true,
												pattern: /^[a-zA-Z0-9\s]*$/,
											})}
										/>
										{errors?.Placement?.type === "pattern" && (
											<label style={{ color: "red", fontStyle: "normal" }}>
												<FormattedMessage id="Alphabetical characters only" />
											</label>
										)}
										{errors?.Placement?.type === "required" && (
											<label style={{ color: "red", fontStyle: "normal" }}>
												<FormattedMessage id="This feild is required" />.
											</label>
										)}
									</div>
								</div>
								<div className="col-md-6 col-12">
									<div className="form-group">
										<label
											htmlFor="first-name-column"
											className="mb-1"
											style={{ color: "#000" }}
										>
											<FormattedMessage id="Measurement Solution" />{" "}
											<svg
												xmlns="http://www.w3.org/2000/svg"
												id="measurementSolutionTooltip"
												style={{ marginLeft: "2px", cursor: "pointer" }}
												width="15"
												height="15"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<circle cx="12" cy="12" r="10" />
												<line x1="12" y1="16" x2="12" y2="12" />
												<line x1="12" y1="8" x2="12.01" y2="8" />
											</svg>
											<UncontrolledTooltip
												placement="top"
												target="measurementSolutionTooltip"
											>
												<FormattedMessage id="What tracking system will you use (Appsflyer, digital turbine, other?)." />
											</UncontrolledTooltip>
										</label>
										<input
											type="text"
											id="measurementSolution"
											className="form-control input-color"
											name="measurementSolution"
											{...register("measurementSolution", {
												required: true,
												pattern: /^[a-zA-Z0-9\s]*$/,
											})}
										/>
										{errors?.measurementSolution?.type === "pattern" && (
											<label style={{ color: "red", fontStyle: "normal" }}>
												<FormattedMessage id="Alphabetical characters only" />
											</label>
										)}
										{errors?.measurementSolution?.type === "required" && (
											<label style={{ color: "red", fontStyle: "normal" }}>
												<FormattedMessage id="This feild is required" />.
											</label>
										)}
									</div>
								</div>
								<div className="col-md-6 col-12">
									<div className="form-group">
										<label
											htmlFor="last-name-column"
											className="mb-1"
											style={{ color: "#000" }}
										>
											<FormattedMessage id="Country / Region for Distribution" />{" "}
											<svg
												xmlns="http://www.w3.org/2000/svg"
												id="countryForDistributionTooltip"
												style={{ marginLeft: "2px", cursor: "pointer" }}
												width="15"
												height="15"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<circle cx="12" cy="12" r="10" />
												<line x1="12" y1="16" x2="12" y2="12" />
												<line x1="12" y1="8" x2="12.01" y2="8" />
											</svg>
											<UncontrolledTooltip
												placement="top"
												target="countryForDistributionTooltip"
											>
												<FormattedMessage id="Territory where app(s) will be distributed." />
											</UncontrolledTooltip>
										</label>
										<input
											type="text"
											id="last-name-column"
											className="form-control input-color"
											name="countryForDistribution"
											{...register("countryForDistribution", {
												required: true,
												pattern: /^[a-zA-Z0-9\s]*$/,
											})}
										/>
										{errors?.countryForDistribution?.type === "pattern" && (
											<label style={{ color: "red", fontStyle: "normal" }}>
												<FormattedMessage id="Alphabetical characters only" />
											</label>
										)}
										{errors?.countryForDistribution?.type === "required" && (
											<label style={{ color: "red", fontStyle: "normal" }}>
												<FormattedMessage id="This feild is required" />.
											</label>
										)}
									</div>
								</div>
								<div className="col-md-6 col-12">
									<div className="form-group">
										<label
											htmlFor="city-column"
											className="mb-1"
											style={{ color: "#000" }}
										>
											<FormattedMessage id="Point of Contact (Email)" />{" "}
											<svg
												xmlns="http://www.w3.org/2000/svg"
												id="pointOfContactTooltip"
												style={{ marginLeft: "2px", cursor: "pointer" }}
												width="15"
												height="15"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<circle cx="12" cy="12" r="10" />
												<line x1="12" y1="16" x2="12" y2="12" />
												<line x1="12" y1="8" x2="12.01" y2="8" />
											</svg>
											<UncontrolledTooltip
												placement="top"
												target="pointOfContactTooltip"
											>
												<FormattedMessage id="Who can we email to follow up further on this distribution request?." />
											</UncontrolledTooltip>
										</label>
										<input
											type="email"
											className="form-control input-color"
											id="validationBioBootstrap"
											name="pointOfContact"
											rows="4"
											spellCheck="false"
											{...register("pointOfContact", { required: true })}
										/>

										{errors?.pointOfContact?.type === "required" && (
											<label style={{ color: "red", fontStyle: "normal" }}>
												<FormattedMessage id="This feild is required" />.
											</label>
										)}
									</div>
								</div>
								<div className="col-md-6 col-12">
									<div className="form-group">
										<label
											htmlFor="first-name-column"
											className="mb-1"
											style={{ color: "#000" }}
										>
											<FormattedMessage id="OEM Brand(s)" />{" "}
											<svg
												xmlns="http://www.w3.org/2000/svg"
												id="oemBrandtooltip"
												style={{ marginLeft: "2px", cursor: "pointer" }}
												width="15"
												height="15"
												viewBox="0 0 24 24"
												fill="none"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											>
												<circle cx="12" cy="12" r="10" />
												<line x1="12" y1="16" x2="12" y2="12" />
												<line x1="12" y1="8" x2="12.01" y2="8" />
											</svg>
											<UncontrolledTooltip
												placement="top"
												target="oemBrandtooltip"
											>
												<FormattedMessage id="If preload, please list OEM Brand(s) covered" />
											</UncontrolledTooltip>
										</label>
										<input
											type="text"
											id="oemBrand"
											className="form-control input-color"
											name="oemBrand"
											{...register("oemBrand", {
												required: false,
												pattern: /^[a-zA-Z0-9\s]*$/,
											})}
										/>
										{errors?.oemBrand?.type === "pattern" && (
											<label style={{ color: "red", fontStyle: "normal" }}>
												<FormattedMessage id="Alphabetical characters only" />
											</label>
										)}
									</div>
								</div>

								<div className="col-12 text-center mt-2 mb-2">
									<button
										type="submit"
										className="btn btn-snapchat btn-md mr-1 waves-effect border-25 waves-float waves-light"
										data-dismiss="modal"
									>
										<FormattedMessage id="Submit" />
									</button>
								</div>
							</div>
						</form>
					</Modal.Body>
				</Modal>
			</div>

			<div
				className="fullscreenDiv d-xl-none d-lg-none d-md-block d-sm-block d-xs-block"
				style={{ textAlign: "center" }}
			>
				<div className="center text-dark font-weight-bolder">
					<h1>
						<FormattedMessage id="Works best on Desktop" />.{" "}
					</h1>
				</div>
			</div>
		</div>
	);
}
