import axios from "axios";
import CryptoJS from "crypto-js";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Spinner,
} from "reactstrap";
import "../app-assets/css/bootstrap-extended.css";
import "../app-assets/css/bootstrap.css";
import "../app-assets/css/colors.css";
import "../app-assets/css/core/menu/menu-types/horizontal-menu.css";
import "../app-assets/css/pages/snapchat-auth.css";
import "../app-assets/css/plugins/forms/form-validation.css";
import SnapLogoWhite from "../app-assets/images/icons/PRIMARY - GHOST.svg";
import SnapRegisterLogo from "../app-assets/images/icons/PRIMARY - GHOST_white.svg";
import SnapLogoTop from "../app-assets/images/pages/PRIMARY-GHOST.svg";
import "../assets/css/style.css";
import CookieCard from "../component/BottomCard";
import { COUNTRY_LIST } from "../constants/index";
import { ROUTES } from "../constants/routes";
import "./style.css";

const Register = (props) => {
	const topstyle = {
		top: "52px !important",
	};
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle = () => setDropdownOpen((prevState) => !prevState);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const [accountType, setAccountType] = useState(
		"Register my company for a Gateway account",
	);
	const [otherTabValue, setOtherTabValue] = useState("");
	const [otherTabFlag, setOtherTabFlag] = useState(false);
	const [modalFlag, setModalFlag] = useState(false);
	const [enablebtn, setEnabledbtn] = useState(false);
	const [companyInfoAvailable, setCompanyInfoAvailable] = useState(true);
	const [countryName, setCountryName] = useState("");
	const { executeRecaptcha } = useGoogleReCaptcha();
	const captchaRef = useRef(null);
	const [loading, setLoading] = useState(true);
	const [captchaToken, setCaptchaToken] = useState(null);

	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			return;
		}
		try {
			const token = await executeRecaptcha("yourAction");
			if (token) {
				return token;
			}
				console.error("ReCaptcha token is null");
		} catch (error) {
			console.error("Error verifying ReCaptcha:", error);
		}
	}, [executeRecaptcha]);

	useEffect(() => {
		setLoading(true);
		handleReCaptchaVerify()
			.then((token) => {
				axios({
					method: "POST",
					url:
						`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/getCaptchaScore`,
					data: {
						gRecaptchaToken: token,
					},
				}).then((res) => {
					res.data.recaptcha.score > 0.7
						? setCaptchaToken(token)
						: setCaptchaToken("");
				});
			})
			.finally(() => {
				setLoading(false);
			});
	}, [handleReCaptchaVerify]);

	const handleRadio = (e) => {
		setOtherTabFlag(true);
		setValue("types_of_business", otherTabValue, {
			shouldValidate: true,
			shouldDirty: true,
		});
	};
	const handleThirdRadio = (e) => {
		setOtherTabFlag(false);
		document.getElementById("other-tab-value").value = "";
		setValue("types_of_business", e.target.value, {
			shouldValidate: true,
			shouldDirty: true,
		});
		setValue("types_of_businessValue", e.target.value, {
			shouldValidate: true,
			shouldDirty: true,
		});
	};

	const handleTextArea = (e) => {
		setOtherTabValue(e.target.value);
		setValue("types_of_business", e.target.value, {
			shouldValidate: true,
			shouldDirty: true,
		});
	};

	function randomString(length, chars) {
		let result = "";
		for (let i = length; i > 0; --i)
			result += chars[Math.floor(Math.random() * chars.length)];
		return result;
	}

	const handleAccoutType = (e) => {
		setAccountType(e.target.value);
		setValue("liketo", e.target.value, {
			shouldValidate: true,
			shouldDirty: true,
		});
	};
	const handleCountryName = (e) => {
		setCountryName(e.target.value);
	};

	useEffect(() => {
		if (accountType === "Get access to my company's existing Gateway account") {
			setCompanyInfoAvailable(false);
		} else {
			setCompanyInfoAvailable(true);
			setValue("liketo", "Register my company for a Gateway account", {
				shouldValidate: true,
				shouldDirty: true,
			});
		}
	}, [accountType]);

	useEffect(() => {
		if (captchaToken && captchaToken.length > 0) {
			setEnabledbtn(true);
		}
	}, [captchaToken]);

	const onSubmit = (dataObject) => {
		const user_id = randomString(
			32,
			"0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
		);
		const data = {
			user_id: user_id,
			like_to: accountType,
			company_name: dataObject.company_name,
			cat: Date.now,
			company_url: dataObject.company_url,
			fname: dataObject.fname,
			snapchat_bundle: dataObject.snapchatbundle,
			status: "awaiting",
			other_business: null,
			types_of_business: dataObject.types_of_business,
			email: dataObject.company_email,
			lname: dataObject.lname,
			user_role: "Admin",
			designation: dataObject.designation,
			country: dataObject.countryCheck,
		};

		axios({
			method: "POST",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/register`,
			data,
			withCredentials: true,
		})
			.then((response) => {
				if (response.status === 200) {
					setModalFlag(true);
					setEnabledbtn(false);
					document.getElementById("register-form").reset();
				}
			})
			.catch((error) => {
				toast.error(error.response.data.msg);
				setEnabledbtn(false);
			})
			.finally(() => {
				captchaRef?.current?.reset();
			});
	};

	useEffect(() => {
		try {
			const generatedFlag = localStorage.getItem("userActiveFlag");
			if (generatedFlag !== null) {
				const bytes = CryptoJS.AES.decrypt(
					generatedFlag,
					process.env.REACT_APP_CRYPTO_JS_DECRYPT_KEY,
				);
				const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
				if (decryptedData === "true") {
					props.history.push("/home");
				} else {
					props.history.push("/register");
				}
			}
		} catch (error) {
			console.error(error);
		}
	}, [props.history]);

	const handleClose = () => {
		setModalFlag(false);
		props.history.push(ROUTES.HOME);
	};

	const handleNavRouter = () => {
		props.history.push(ROUTES.HOME);
	};

	const liketo = register("liketo", { required: true });
	const countryCheck = register("countryCheck", { required: true });

	const types_of_businessValue = register("types_of_business", {
		required: true,
	});

	return (
		<div>
			<div
				className="horizontal-layout horizontal-menu blank-page navbar-floating footer-fixed"
				data-open="hover"
				data-menu="horizontal-menu"
				data-col="blank-page"
			>
				<nav
					className="header-navbar navbar-expand-lg navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center bg-black"
					data-nav="brand-center"
				>
					<div className="navbar-header d-xl-block d-none" />
					<div className="navbar-container d-flex content">
						<div className="bookmark-wrapper d-flex align-items-center">
							<ul
								className="nav navbar-nav d-lg-none"
								onClick={handleNavRouter}
							>
								<li className="nav-item">
									<a className="nav-link">
										{" "}
										<img
											className="snap-main-icon-homepage-wrapper"
											src={SnapLogoTop}
											style={{ width: "29px", marginRight: "10px" }}
										/>
										<span className="" style={{ fontSize: "18px" }}>
											{" "}
											<FormattedMessage id="Mobile Partner Gateway" />
										</span>
									</a>
								</li>
							</ul>
							<ul className="nav navbar-nav" onClick={handleNavRouter}>
								<li className="nav-item d-none d-lg-block">
									<a className="nav-link">
										{" "}
										<img
											alt={SnapLogoTop}
											src={SnapLogoTop}
											style={{ width: "29px", marginRight: "10px" }}
										/>
										<span style={{ fontSize: "18px" }}>
											<FormattedMessage id="Mobile Partner Gateway" />
										</span>{" "}
									</a>
								</li>
							</ul>
						</div>
						<div className="nav navbar-nav align-items-center ml-auto">
							<div className="nav navbar-nav d-xl-none">
								<Dropdown
									isOpen={dropdownOpen}
									toggle={toggle}
									direction="down"
								>
									<DropdownToggle
										tag="span"
										data-toggle="dropdown"
										aria-expanded={dropdownOpen}
										className="text-white"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											stroke="currentColor"
											stroke-width="2"
											stroke-linecap="round"
											stroke-linejoin="round"
										>
											<line x1="3" y1="12" x2="21" y2="12" />
											<line x1="3" y1="6" x2="21" y2="6" />
											<line x1="3" y1="18" x2="21" y2="18" />
										</svg>
									</DropdownToggle>
									<DropdownMenu className="toppadding" style={topstyle}>
										<DropdownItem tag="a" href="/login">
											<FormattedMessage id="Log In" />
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</div>
							<ul className="nav navbar-nav d-none d-lg-block">
								<a
									href="/login"
									className="btn btn-snapchat border-25 waves-effect waves-float fs-18 waves-light"
								>
									<FormattedMessage id="Log In" />
								</a>
							</ul>
						</div>
					</div>
				</nav>
				{/* <!-- END: Header--> */}
				{/* <!-- BEGIN: Content--> */}
				<div className="app-content content bg-black">
					<div className="content-overlay" />
					<div className="header-navbar-shadow" />
					<div className="content-wrapper">
						<div className="content-header row" />
						<div className="content-body back-img ">
							<div
								className="auth-wrapper auth-v1 px-2"
								style={{ backgroundAttachment: "fixed" }}
							>
								<div className="auth-inner py-2">
									{/* <!-- Login v1 --> */}
									<div
										className="card mb-0"
										style={{ borderRadius: "15px", marginTop: "50px" }}
									>
										<div className="card-body" style={{ padding: "0px" }}>
											<div className="row mx-0">
												<div
													className="col-lg-6"
													style={{
														backgroundColor: "yellow",
														borderTopLeftRadius: "15px",
														borderBottomLeftRadius: "15px",
													}}
												>
													<div
														className="col-lg-12 text-center"
														style={{ marginTop: "190px" }}
													>
														<img
															alt={SnapLogoWhite}
															src={SnapLogoWhite}
															style={{ width: "35px" }}
														/>
													</div>
													<div className="col-lg-12 text-center mt-1 px-2">
														<p className="text-darkk" style={{ color: "#000" }}>
															<FormattedMessage id="Request Access for Mobile Partner Gateway account" />
														</p>
													</div>
												</div>
												<div className="col-lg-6">
													<div
														style={{
															width: "100%",
															textAlign: "right",
															marginTop: "10px",
														}}
													>
														<a href="/">X</a>
													</div>
													<h1
														className="card-title mb-1 pt-1  mt-1 font-weight-bolder"
														style={{
															fontSize: "22px",
															fontWeight: "bold",
															color: "#000",
														}}
													>
														<FormattedMessage id="Request Access" />
													</h1>
													{loading ? (
														<div
															style={{
																display: "flex",
																justifyContent: "center",
																alignItems: "center",
																width: "100%",
																height: "57vh",
																marginTop: "-24px",
															}}
														>
															<Spinner
																style={{
																	height: "50px",
																	width: "50px",
																	fontSize: "24px",
																}}
															/>
														</div>
													) : (
														<form
															className="request-access-form pt-1 mt-2 mb-2"
															id="register-form"
															action=""
															style={{ height: "435px", overflow: "scroll" }}
															onSubmit={handleSubmit(onSubmit)}
														>
															<div className="row">
																<div className="col-md-6 col-12">
																	<div className="form-group">
																		<label
																			htmlFor="fname"
																			style={{ color: "#000" }}
																		>
																			<FormattedMessage id="First Name" />
																		</label>
																		<input
																			type="text"
																			id="fname"
																			className="form-control input-color capitalize"
																			name="fname"
																			{...register("fname", {
																				required: true,
																				pattern: /^[a-zA-Z0-9\s]*$/,
																			})}
																		/>
																		{errors?.fname?.type === "pattern" && (
																			<label
																				style={{
																					color: "red",
																					fontStyle: "normal",
																				}}
																			>
																				<FormattedMessage id="Alphabetical characters only" />
																			</label>
																		)}
																		{errors?.fname?.type === "required" && (
																			<label
																				style={{
																					color: "red",
																					fontStyle: "normal",
																				}}
																			>
																				<FormattedMessage id="This field is required" />
																			</label>
																		)}
																	</div>
																</div>
																<div className="col-md-6 col-12">
																	<div className="form-group">
																		<label
																			htmlFor="lname"
																			style={{ color: "#000" }}
																		>
																			<FormattedMessage id="Last Name" />
																		</label>
																		<input
																			type="text"
																			id="lname"
																			class="form-control input-color capitalize"
																			name="lname"
																			{...register("lname", {
																				required: true,
																				pattern: /^[A-Za-z]+$/i,
																			})}
																		/>
																		{errors?.lname?.type === "pattern" && (
																			<label
																				style={{
																					color: "red",
																					fontStyle: "normal",
																				}}
																			>
																				<FormattedMessage id="Alphabetical characters only" />
																			</label>
																		)}
																		{errors?.lname?.type === "required" && (
																			<label
																				style={{
																					color: "red",
																					fontStyle: "normal",
																				}}
																			>
																				<FormattedMessage id="This field is required" />
																			</label>
																		)}
																	</div>
																</div>

																<div className="col-md-12 col-12">
																	<div className="form-group mb-0">
																		<label
																			htmlFor="company-email"
																			style={{ color: "#000" }}
																		>
																			<FormattedMessage id="Company Email Address" />
																		</label>
																		<input
																			type="email"
																			className="form-control input-color"
																			id="company_email"
																			name="company_email"
																			aria-describedby="company_email"
																			{...register("company_email", {
																				required: true,
																			})}
																		/>
																		{errors.company_email && (
																			<label
																				style={{
																					color: "red",
																					fontStyle: "normal",
																				}}
																			>
																				<FormattedMessage id="This field is required" />
																			</label>
																		)}
																	</div>
																	<div className="form-group text-right">
																		<a
																			href="#"
																			className="text-dark font-weight-bold"
																			style={{ fontSize: "9px", color: "#000" }}
																		>
																			[
																			<FormattedMessage id="Note: Once approved, you will receive login details to this work email" />
																			]
																		</a>
																	</div>
																</div>
																<div className="col-md-12 col-12">
																	<div className="form-group">
																		<label
																			htmlFor="designation"
																			style={{ color: "#000" }}
																		>
																			<FormattedMessage id="Job Title or Designation" />
																		</label>
																		<input
																			type="text"
																			className="form-control input-color"
																			id="designation"
																			name="designation"
																			aria-describedby="designation"
																			{...register("designation", {
																				required: true,
																				pattern: /^[a-zA-Z\s]*$/,
																			})}
																		/>
																		{errors?.designation?.type ===
																			"pattern" && (
																			<label
																				style={{
																					color: "red",
																					fontStyle: "normal",
																				}}
																			>
																				<FormattedMessage id="Alphabetical characters only" />
																			</label>
																		)}
																		{errors?.designation?.type ===
																			"required" && (
																			<label
																				style={{
																					color: "red",
																					fontStyle: "normal",
																				}}
																			>
																				<FormattedMessage id="This field is required" />
																			</label>
																		)}
																	</div>
																</div>
																<div className="col-md-12 col-12">
																	<div className="form-group">
																		<label
																			htmlFor="company-email"
																			style={{ color: "#000" }}
																		>
																			<FormattedMessage id="Primary Country" />:
																		</label>
																		<select
																			as="select"
																			className="form-control input-color"
																			name="countryCheck"
																			id="countryCheck"
																			onChange={(e) => {
																				countryCheck.onChange(e);
																				handleCountryName(e);
																			}}
																			{...register("countryCheck", {
																				required: true,
																			})}
																		>
																			{/* <option value=''><FormattedMessage id="Please Select"/></option> */}
																			<FormattedMessage id="Please select">
																				{(message) => (
																					<option value="">{message}</option>
																				)}
																			</FormattedMessage>
																			{COUNTRY_LIST.map((countryName) => {
																				return (
																					<option
																						value={countryName}
																						style={{ color: "#000" }}
																					>
																						{countryName}
																					</option>
																				);
																			})}
																		</select>
																		{errors.countryCheck && (
																			<label
																				style={{
																					color: "red",
																					fontStyle: "normal",
																				}}
																			>
																				<FormattedMessage id="This field is required" />
																			</label>
																		)}
																	</div>
																</div>
																<div className="col-md-12 col-12">
																	<div className="form-group">
																		<label
																			htmlFor="company-email"
																			style={{ color: "#000" }}
																		>
																			<FormattedMessage id="I'd like to" />:
																		</label>
																		<select
																			as="select"
																			className="form-control input-color"
																			name="liketo"
																			id="liketo"
																			onChange={(e) => {
																				liketo.onChange(e);
																				handleAccoutType(e);
																			}}
																		>
																			<FormattedMessage
																				id={
																					"Register my company for a Gateway account"
																				}
																			>
																				{(message) => (
																					<option value="Register my company for a Gateway account">
																						{message}
																					</option>
																				)}
																			</FormattedMessage>
																			<FormattedMessage
																				id={
																					"Get access to my company's existing Gateway account"
																				}
																			>
																				{(message) => (
																					<option value="Get access to my company's existing Gateway account">
																						{message}
																					</option>
																				)}
																			</FormattedMessage>
																		</select>
																		{errors.liketo && (
																			<label
																				style={{
																					color: "red",
																					fontStyle: "normal",
																				}}
																			>
																				<FormattedMessage id="This field is required" />
																			</label>
																		)}
																	</div>
																</div>

																<div className="col-md-12 col-12">
																	<div className="form-group" id="form-int">
																		<label
																			className="d-block"
																			style={{ color: "#000" }}
																		>
																			<FormattedMessage id="We are interested in" />
																		</label>
																		<div className="custom-control custom-checkbox my-50 custom-control-snapchat">
																			<input
																				type="checkbox"
																				id="snapchatbundle1"
																				name="snapchatbundle"
																				value="Zero or Flat rating Snapchat"
																				className="custom-control-input"
																				{...register("snapchatbundle", {
																					required: true,
																				})}
																			/>
																			<label
																				className="custom-control-label fs-10 text-darkk mt-25"
																				htmlFor="snapchatbundle1"
																				style={{ color: "#000" }}
																			>
																				<FormattedMessage id="Zero or Flat rating Snapchat" />
																			</label>
																		</div>
																		<div className="custom-control custom-checkbox my-50 custom-control-snapchat">
																			<input
																				type="checkbox"
																				id="snapchatbundle7"
																				name="snapchatbundle"
																				value="Snapchat bundles or data packages"
																				className="custom-control-input"
																				{...register("snapchatbundle", {
																					required: true,
																				})}
																			/>
																			<label
																				className="custom-control-label fs-10 text-darkk mt-25"
																				htmlFor="snapchatbundle7"
																				style={{ color: "#000" }}
																			>
																				<FormattedMessage id="Snapchat bundles or data packages" />
																			</label>
																		</div>
																		<div className="custom-control custom-checkbox my-50 custom-control-snapchat">
																			<input
																				type="checkbox"
																				id="snapchatbundle2"
																				name="snapchatbundle"
																				value="Creating data offers around Snapchat"
																				className="custom-control-input"
																				{...register("snapchatbundle", {
																					required: true,
																				})}
																			/>
																			<label
																				className="custom-control-label fs-10 text-darkk mt-25"
																				htmlFor="snapchatbundle2"
																				style={{ color: "#000" }}
																			>
																				<FormattedMessage
																					id="Creating
                                                              data offers around Snapchat"
																				/>
																			</label>
																		</div>
																		<div className="custom-control custom-checkbox my-50 custom-control-snapchat">
																			<input
																				type="checkbox"
																				id="snapchatbundle3"
																				name="snapchatbundle"
																				value="Preloading Snapchat on devices"
																				className="custom-control-input"
																				{...register("snapchatbundle", {
																					required: true,
																				})}
																			/>
																			<label
																				className="custom-control-label fs-10 text-darkk mt-25"
																				for="snapchatbundle3"
																				style={{ color: "#000" }}
																			>
																				<FormattedMessage id="Preloading Snapchat on devices" />
																			</label>
																		</div>
																		<div className="custom-control custom-checkbox my-50 custom-control-snapchat">
																			<input
																				type="checkbox"
																				id="snapchatbundle4"
																				name="snapchatbundle"
																				value="Other types of promotions"
																				className="custom-control-input"
																				{...register("snapchatbundle", {
																					required: true,
																				})}
																			/>
																			<label
																				className="custom-control-label fs-10 text-darkk mt-25"
																				htmlFor="snapchatbundle4"
																				style={{ color: "#000" }}
																			>
																				<FormattedMessage
																					id="Other
                                                              types of promotions"
																				/>
																			</label>
																		</div>
																		<div className="custom-control custom-checkbox my-50 custom-control-snapchat">
																			<input
																				type="checkbox"
																				id="snapchatbundle5"
																				name="snapchatbundle"
																				value="Reporting a tech issue"
																				className="custom-control-input"
																				{...register("snapchatbundle", {
																					required: true,
																				})}
																			/>
																			<label
																				className="custom-control-label fs-10 text-darkk mt-25"
																				htmlFor="snapchatbundle5"
																				style={{ color: "#000" }}
																			>
																				<FormattedMessage
																					id="Reporting
                                                              a tech issue"
																				/>
																			</label>
																		</div>
																		<div className="custom-control custom-checkbox my-50 custom-control-snapchat">
																			<input
																				type="checkbox"
																				id="snapchatbundle6"
																				name="snapchatbundle"
																				value="Data & Insights"
																				className="custom-control-input"
																				{...register("snapchatbundle", {
																					required: true,
																				})}
																			/>
																			<label
																				className="custom-control-label fs-10 text-darkk mt-25"
																				htmlFor="snapchatbundle6"
																				style={{ color: "#000" }}
																			>
																				<FormattedMessage
																					id="Data &
                                                              Insights"
																				/>
																			</label>
																		</div>
																		{errors.snapchatbundle && (
																			<label
																				style={{
																					color: "red",
																					fontStyle: "normal",
																				}}
																			>
																				<FormattedMessage id="This field is required" />
																			</label>
																		)}
																	</div>
																</div>
																<div className="col-md-12 col-12">
																	<div className="form-group">
																		<label
																			htmlFor="company-email"
																			style={{ color: "#000" }}
																		>
																			<FormattedMessage id="Company Name" />
																		</label>
																		<input
																			type="text"
																			className="form-control input-color capitalize"
																			id="company_name"
																			name="company_name"
																			aria-describedby="company_name"
																			{...register("company_name", {
																				required: true,
																				pattern: /^[a-zA-Z0-9()\s]*$/,
																			})}
																		/>
																		{errors?.company_name?.type ===
																			"pattern" && (
																			<label
																				style={{
																					color: "red",
																					fontStyle: "normal",
																				}}
																			>
																				<FormattedMessage id="Alphabetical characters only" />
																			</label>
																		)}
																		{errors?.company_name?.type ===
																			"required" && (
																			<label
																				style={{
																					color: "red",
																					fontStyle: "normal",
																				}}
																			>
																				<FormattedMessage id="This field is required" />
																			</label>
																		)}
																	</div>
																</div>

																<div className="col-md-12 col-12" id="com-url">
																	<div className="form-group">
																		<label
																			htmlFor="company-email"
																			style={{ color: "#000" }}
																		>
																			<FormattedMessage id="Company URL" />
																		</label>
																		<input
																			type="text"
																			className="form-control input-color"
																			id="company_url"
																			name="company_url"
																			aria-describedby="company-url"
																			{...register("company_url", {
																				required: true,
																				pattern:
																					/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
																			})}
																		/>
																		{errors?.company_url?.type ===
																			"pattern" && (
																			<label
																				style={{
																					color: "red",
																					fontStyle: "normal",
																				}}
																			>
																				<FormattedMessage id="Please enter a valid URL" />{" "}
																				(e.g. https://snapchat.com)
																			</label>
																		)}
																		{errors?.company_url?.type ===
																			"required" && (
																			<label
																				style={{
																					color: "red",
																					fontStyle: "normal",
																				}}
																			>
																				<FormattedMessage id="This field is required" />
																			</label>
																		)}
																	</div>
																</div>
																<div
																	className="col-md-12 col-12"
																	id="types-business"
																>
																	<div className="form-group">
																		<label
																			className="d-block"
																			style={{ color: "#000" }}
																		>
																			<FormattedMessage id="Primary types of business" />
																		</label>
																		<div className="custom-control custom-radio my-50">
																			<input
																				type="radio"
																				id="type_business1"
																				name="types_of_business"
																				value="Mobile Operator"
																				className="custom-control-input"
																				onChange={(e) => {
																					types_of_businessValue.onChange(e);
																					handleThirdRadio(e);
																				}}
																			/>
																			<label
																				className="custom-control-label fs-10 mt-25"
																				htmlFor="type_business1"
																				style={{ color: "#000" }}
																			>
																				<FormattedMessage
																					id="Mobile
                                                              Operator"
																				/>
																			</label>
																		</div>
																		<div className="custom-control custom-radio my-50">
																			<input
																				type="radio"
																				id="type_business2"
																				name="types_of_business"
																				value="OEM"
																				className="custom-control-input"
																				onChange={(e) => {
																					types_of_businessValue.onChange(e);
																					handleThirdRadio(e);
																				}}
																			/>
																			<label
																				className="custom-control-label fs-10 mt-25"
																				htmlFor="type_business2"
																				style={{ color: "#000" }}
																			>
																				<FormattedMessage id="OEM" />
																			</label>
																		</div>
																		<div className="custom-control custom-radio my-50">
																			<input
																				type="radio"
																				id="type_business3"
																				name="types_of_business"
																				value="App Store"
																				className="custom-control-input"
																				onChange={(e) => {
																					types_of_businessValue.onChange(e);
																					handleThirdRadio(e);
																				}}
																			/>
																			<label
																				className="custom-control-label fs-10 mt-25"
																				htmlFor="type_business3"
																				style={{ color: "#000" }}
																			>
																				<FormattedMessage id="App Store" />
																			</label>
																		</div>
																		<div className="custom-control custom-radio my-50">
																			<input
																				type="radio"
																				id="other-tab-value"
																				name="types_of_business"
																				className="custom-control-input"
																				onChange={(e) => {
																					types_of_businessValue.onChange(e);
																					handleRadio(e);
																				}}
																			/>
																			<label
																				className="custom-control-label fs-10 mt-25"
																				htmlFor="other-tab-value"
																				style={{ color: "#000" }}
																			>
																				<FormattedMessage
																					id="Other: Please
                                                              Specify"
																				/>
																			</label>
																		</div>
																		{otherTabFlag && (
																			<textarea
																				htmlFor="other-tab-value"
																				className="form-control input-color"
																				id="othermsg"
																				name="types_of_business"
																				rows="2"
																				onChange={(e) => {
																					types_of_businessValue.onChange(e);
																					handleTextArea(e);
																				}}
																				{...register("types_of_business", {
																					required: true,
																					pattern: /^[a-zA-Z\s]*$/,
																				})}
																			/>
																		)}
																	</div>
																	{errors.types_of_business && (
																		<label
																			style={{
																				color: "red",
																				fontStyle: "normal",
																			}}
																		>
																			<FormattedMessage id="This field is required" />
																		</label>
																	)}
																</div>

																<div className="col-md-12 col-12">
																	<div className="form-group">
																		<div
																			className="px-2 fs-10 text-center  font-weight-bold"
																			style={{ lineHeight: "1.1rem" }}
																		>
																			<p style={{ color: "#000" }}>
																				<FormattedMessage id="By clicking 'Accept & Submit' I represent and warrant that I am authorized to submit this request on behalf of the above Company, and I agree on behalf of the Company that I have read the" />{" "}
																				<a
																					href="https://www.snap.com/en-US/privacy/privacy-policy"
																					target="_blank"
																					rel="noreferrer"
																				>
																					<FormattedMessage id="Privacy Policy" />{" "}
																				</a>
																				<FormattedMessage id="and agree to the" />{" "}
																				<a
																					href="https://www.snap.com/en-US/terms"
																					target="_blank"
																					rel="noreferrer"
																				>
																					<FormattedMessage id="Terms of Service" />
																				</a>{" "}
																				&{" "}
																				<a
																					href="https://www.snap.com/en-US/terms/mobile-partner-gateway-terms"
																					target="_blank"
																					rel="noreferrer"
																				>
																					<FormattedMessage id="Mobile Partner Gateway Terms" />
																				</a>
																			</p>
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-12">
																	<div className="form-group text-center d-flex flex-column align-items-center">
																		<div className="mb-2">
																			{captchaToken &&
																			captchaToken.length > 0 ? null : (
																				<ReCAPTCHA
																					sitekey={
																						process.env
																							.REACT_APP_GOOGLE_RECAPTCHA_V2_KEY
																					}
																					ref={captchaRef}
																					onChange={(value) => {
																						setCaptchaToken(value);
																					}}
																					onExpired={() => {
																						setCaptchaToken("");
																					}}
																					onErrored={() => {
																						setCaptchaToken("");
																					}}
																				/>
																			)}
																		</div>
																		<button
																			type="submit"
																			disabled={!enablebtn}
																			className={
																				`btn btn-snapchat border-25 fs-12 w-50${!enablebtn
																					? " disabled-btn-main-wrapper"
																					: ""}`
																			}
																			data-target="#registersuccessmodal"
																			style={{ color: "#FFF" }}
																		>
																			<FormattedMessage id="Accept & Submit" />
																		</button>
																	</div>
																</div>
															</div>
														</form>
													)}
												</div>
											</div>
										</div>
									</div>
									{/* <!-- /Login v1 --> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal show={modalFlag} onHide={handleClose} centered>
					<Modal.Body>
						<div className="col-lg-12 text-center pt-2 mt-2">
							<img alt={SnapRegisterLogo} src={SnapRegisterLogo} />
						</div>
						<div className="col-lg-12 text-center pt-1">
							<h4 className="font-weight-bolder text-darkk">
								<FormattedMessage id="Thank you" />
							</h4>
						</div>
						<div className="col-lg-12 text-center font-weight-bold text-darkk py-2">
							<p>
								<FormattedMessage id="Your request is under review. We will email you the status of your request in 4 - 5 working days. Thanks for your patience" />
								.
							</p>
						</div>
						<div className="col-lg-12 text-center mb-3">
							<button
								type="button"
								className="btn btn-snapchat border-25"
								style={{ padding: "15px 110px" }}
								onClick={() => handleClose()}
							>
								<FormattedMessage id="Done" />
							</button>
						</div>
					</Modal.Body>
				</Modal>
			</div>

			<footer className="footer footer-fixed bg-black">
				<p className="clearfix mb-0">
					<span className="float-md-left d-block d-md-inline-block mt-25">
						<a
							className="mr-1 fs-12 text-white"
							href="https://www.snap.com/en-US/terms/mobile-partner-gateway-terms"
							target="_blank"
							style={{ color: "#000" }}
							rel="noreferrer"
						>
							<FormattedMessage id="Terms & Conditions" />
						</a>
						<span className=" d-sm-inline-block">
							<a
								className="mr-50 fs-12 text-white"
								href="https://www.snap.com/en-US/privacy/privacy-policy"
								target="_blank"
								style={{ color: "#000" }}
								rel="noreferrer"
							>
								<FormattedMessage id="Privacy Policy" />
							</a>
						</span>
					</span>
				</p>
			</footer>

			<CookieCard />
		</div>
	);
};
export default Register;
