import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import UploadImage from "../app-assets/images/icons/Icon awesome-file-upload.svg";
import LoaderImage from "../app-assets/images/icons/loaderIcons.gif";

const MarketingModal = (props) => {
	const [attachment, setAttachment] = useState(null);
	const [modalFileData, setModalFileData] = useState("");
	const [marketingmodalFileData, setMarketingmodalFileData] = useState("");
	const [modalFileDataFlag, setModalFileDataFlag] = useState(false);
	const [enabledDisablebtn, setEnabledisabledbtn] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const modalFileDataValue = register("fileisuploaded", { required: true });

	const closeModal = () => {
		props.handleClose();
	};

	const onSubmit = (data) => {
		setEnabledisabledbtn(true);
		data = {
			...data,
			attachment: attachment,
			selectedOfferId: props.selectedOfferId,
		};
		axios({
			method: "POST",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/marketingAssets`,
			data,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		})
			.then((response) => {
				if (response.status === 200) {
					setEnabledisabledbtn(false);
					toast.success("Marketing assets submitted successfully ");
					document.getElementById("marketingForm").reset();
					props.handleClose();
				}
			})
			.catch((err) => {
				setEnabledisabledbtn(false);
				toast.error("something went wrong");
			});
	};

	const handleModalFile = (event) => {
		const reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);
		reader.onload = () => {
			setAttachment({
				name: event.target.files[0].name,
				type: event.target.files[0].type,
				size: `${Math.round(event.target.files[0].size / 1000)} kB`,
				base64: reader.result,
				file: event.target.files[0],
			});
		};
		setModalFileData(event.target.files[0]);
		setValue("fileisuploaded", event.target.files[0], {
			shouldValidate: true,
			shouldDirty: true,
		});
		if (event.target.files[0].name) {
			setModalFileDataFlag(true);
		}
	};

	return (
		<Modal show={props.modalFlag} onHide={closeModal} centered>
			{enabledDisablebtn && (
				<div className="loader-component">
					<img alt="loader" src={LoaderImage} className="image-loader-offers" />
				</div>
			)}
			<Modal.Body>
				<div className="col-lg-12 text-center font-weight-bold text-darkk py-2">
					<h4>Partner Configuration Approved</h4>
				</div>
				<h6 className="card-title text-darkk">
					Request for brand/marketing approval form
				</h6>

				<form
					className="form"
					onSubmit={handleSubmit(onSubmit)}
					id="marketingForm"
				>
					<div className="row">
						<div className="col-md-12 col-12">
							<div className="form-group">
								<label htmlFor="marketing_file" className="mb-1">
									File
								</label>
								<br />
								<div
									className="height-100 text-center input-color"
									style={{
										border: "1px solid #D8D6DE",
										borderRadius: "0.357rem",
									}}
								>
									<label htmlFor="marketingmodalFileData">
										<img
											htmlFor="marketingmodalFileData"
											alt={UploadImage}
											src={UploadImage}
											style={{
												width: "25px",
												marginTop: "25px",
												cursor: "pointer",
											}}
										/>
									</label>
									{modalFileDataFlag ? (
										<p className="text-center fs-10 mt-50">
											{modalFileData.name}
										</p>
									) : (
										<p
											className="text-center fs-10 mt-50"
											style={{ color: "#000" }}
										>
											Upload file (pptx, image)
										</p>
									)}
									<input
										type="hidden"
										name="fileisuploaded"
										value={modalFileData.name}
									/>
									<input
										type="file"
										id="marketingmodalFileData"
										style={{ display: "none", visibility: "none" }}
										name="marketingmodalFileData"
										onChange={(e) => {
											modalFileDataValue.onChange(e);
											handleModalFile(e);
										}}
									/>
								</div>
								{errors.fileisuploaded && (
									<label style={{ color: "red", fontStyle: "normal" }}>
										File feild is required.
									</label>
								)}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 col-12">
							<div className="form-group">
								<label htmlFor="city-column" className="mb-1">
									Description
								</label>
								<textarea
									className="form-control input-color"
									id="validationBioBootstrap"
									name="marketingFormDesc"
									rows="4"
									{...register("marketingFormDesc", {
										required: true,
										pattern: /^[a-zA-Z0-9\s]*$/,
									})}
								/>
								{errors?.marketingFormDesc?.type === "pattern" && (
									<label style={{ color: "red", fontStyle: "normal" }}>
										Alphabetical characters only
									</label>
								)}
								{errors?.marketingFormDesc?.type === "required" && (
									<label style={{ color: "red", fontStyle: "normal" }}>
										This field is required.
									</label>
								)}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 col-12">
							<div className="form-group">
								<label htmlFor="last-name-column" className="mb-1">
									Point of Contact (Email)
								</label>
								<input
									type="email"
									id="last-name-column"
									className="form-control input-color"
									name="marketingPointOfContact"
									{...register("marketingPointOfContact", { required: true })}
								/>
								{errors.marketingPointOfContact && (
									<label style={{ color: "red", fontStyle: "normal" }}>
										Point of contact field is required.
									</label>
								)}
							</div>
						</div>
					</div>
					<div className="col-lg-12 text-center mb-3">
						<button
							type="submit"
							className={
								`btn btn-snapchat border-25${enabledDisablebtn ? " disabled-btn-main-wrapper" : ""}`
							}
						>
							Submit
						</button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};
export default MarketingModal;
