import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Login from "./component/Login";
import Register from "./component/Register";
import { ROUTES } from "./constants/routes";
import Dashboard from "./pages/Dashboard/Dashboard";
import DataInsight from "./pages/Data&Insight/Data&Insight";
import DevicePage from "./pages/Device/Device";
import FileSharing from "./pages/FileSharing";
import FourthPage from "./pages/Fourth/Fourth";
import HomePage from "./pages/Homepage/HomePage";
import MarketPage from "./pages/Marketing/Marketing";
import Offer from "./pages/Offer/Offer";
import PageNotFound from "./pages/PageNotFound";
import RedirectToAdmin from "./pages/RedirectToAdmin";
import ResourcePage from "./pages/Resources/Resources";
import SettingPage from "./pages/Setting/SettingPage";
import SupportPage from "./pages/Support/SupportPage";

function App() {
	return (
		<div>
			<ToastContainer />
			<Router>
				<Switch>
					<Route exact path={ROUTES.HOME} component={HomePage} />
					<Route path={ROUTES.DASHBOARD} component={Dashboard} />
					<Route path={ROUTES.LOGIN} component={Login} />
					<Route path={ROUTES.REGISTER} component={Register} />
					<Route path={ROUTES.OFFER} component={Offer} />
					<Route path={ROUTES.DEVICE} component={DevicePage} />
					<Route path="/fourth" component={FourthPage} />
					<Route path={ROUTES.MARKETING} component={MarketPage} />
					<Route path={ROUTES.RESOURCES} component={ResourcePage} />
					<Route path={ROUTES.SETTING} component={SettingPage} />
					<Route path={ROUTES.SUPPORT} component={SupportPage} />
					<Route path={ROUTES.INSIGHT} component={DataInsight} />
					<Route path={ROUTES.FILE_SHARING} component={FileSharing} />
					<Route path={ROUTES.ADMIN} component={RedirectToAdmin} />
					<Route component={PageNotFound} />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
