import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import "./style.css";

const BottomCard = () => {
	const [cookieModal, setCookieModal] = useState(false);

	const handleAcceptCookie = (e) => {
		document.cookie = "cookiesApproval=true;path=/;secure";
		const element = document.getElementById("cookieoverlay");
		element.classList.remove("d-block");
		element.classList.add("d-none");
	};

	useEffect(() => {
		const CookieCardValue = Cookies.get("cookiesApproval");
		if (CookieCardValue) {
			setCookieModal(false);
		} else {
			setCookieModal(true);
		}
	}, []);

	const cookieToggle = () => {
		setCookieModal(false);
	};
	return (
		<>
			<div
				className={`cookie-overlay p-4 ${cookieModal ? "d-block" : "d-none"} `}
				id="cookieoverlay"
			>
				<div className="d-flex row">
					<div className="col-lg-8">
						<p>
							<div style={{ color: "#606060" }}>
								<FormattedMessage id="This website uses cookies in order to offer you the most relevant information. Please accept cookies for optimal performance." />
							</div>
						</p>
					</div>
					<div className="col-lg-4">
						<button
							className="btn btn-snapchat-cookie mr-1 accept-cookies mb-50"
							onClick={(e) => handleAcceptCookie()}
						>
							<div>
								<FormattedMessage id="Yes, I accept Cookies" />
							</div>
						</button>
						<button
							className="btn btn-danger-cookie close-cookies mb-50"
							onClick={cookieToggle}
						>
							<div>
								<FormattedMessage id="Cancel" />
							</div>
						</button>
					</div>
				</div>
			</div>
		</>
	);
};
export default BottomCard;
