import React from "react";
import ReactDOM from "react-dom";
import "../src/app-assets/css/bootstrap-extended.css";
import "../src/app-assets/css/colors.css";
import "../src/app-assets/css/components.css";
import "../src/app-assets/css/core/menu/menu-types/vertical-menu.css";
import "../src/app-assets/css/themes/bordered-layout.css";
import "../src/app-assets/css/themes/dark-layout.css";
import "../src/app-assets/css/themes/semi-dark-layout.css";
import "../src/app-assets/fontFamily/Program-OT-Black.otf";
import "../src/app-assets/fontFamily/Program-OT-Bold.otf";
import "../src/app-assets/vendors/css/vendors.min.css";
import "../src/assets/css/style.css";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
