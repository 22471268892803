import axios from "axios";
import "flatpickr/dist/themes/light.css";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { ReactComponent as X } from "../../app-assets/images/icons/X-icon.svg";
import { ReactComponent as Dot } from "../../app-assets/images/icons/_Dot.svg";
import { ReactComponent as Mail } from "../../app-assets/images/icons/mail.svg";
import { ReactComponent as Minus } from "../../app-assets/images/icons/minus.svg";
import SideNav from "../../component/SideNavigator";
import TablePagination from "../../component/TablePagination";
import Header from "../../component/TopHeader";
import MarketingModal from "../../component/marketingModal";
import verifyUser from "../Verifier";
import "./offer.css";

import { Modal } from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import "sweetalert2/dist/sweetalert2.css";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { ROUTES } from "../../constants/routes";

const OfferPage = (props) => {
	const MySwal = withReactContent(Swal);
	const intl = useIntl();
	const [firstTabFlag, setFirstTabFlag] = useState(true);
	const [secondTabFlag, setSecondTabFlag] = useState(false);
	const [getOfferData, setGetOfferData] = useState([]);
	const [modalFlag, setModalFlag] = useState(false);
	const [selectedOfferId, setSelectedOfferId] = useState("");
	const [offerLaunchId, setOfferLaunchId] = useState("");
	const [activeOffersData, setActiveOffersData] = useState([]);
	const [endOfferId, setEndOfferId] = useState("");
	const [email, setEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [offerLaunchName, setOfferName] = useState("");
	const [endOfferName, setEndOfferName] = useState("");

	const [reqModalFlag, setReqModalFlag] = useState(false);
	const [endModalFlag, setEndModalFlag] = useState(false);
	const [filters, setFilters] = useState("");
	const [totalItemsCount, setTotalItemsCount] = useState(0);
	const [currentPageCount, setCurrentPageCount] = useState(1);
	const [loading, setLoading] = useState(false);
	const [offerLoader, setOfferLoader] = useState(false);
	const { search } = useLocation();
	const selectRef = useRef(null);
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const handleFirstTab = () => {
		setFirstTabFlag(true);
		setSecondTabFlag(false);
	};
	const handleSecondTab = () => {
		getAllOffers();
		setFirstTabFlag(false);
		setSecondTabFlag(true);
	};

	const onSubmit = (data) => {
		setLoading(true);
		axios({
			method: "POST",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/create-campaign`,
			data: data,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		})
			.then(() => {
				toast.success("Your campaign request has been sent successfully");
			})
			.catch((err) => {
				setLoading(false);
				toast.error(err.response.data.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		verifyUser()
			.then((data) => {
				if (data.data.code === "100" && data.data.email) {
					setEmail(data.data.email);
					setFirstName(data.data.fname);
					setLastName(data.data.lname);
					setValue("lastName", data.data.lname, { shouldValidate: true });
					setValue("firstName", data.data.fname, { shouldValidate: true });
					setValue("email", data.data.email, { shouldValidate: true });
					getAllOffers();
				} else {
					props.history.push(ROUTES.LOGIN);
				}
			})
			.catch(() => {
				props.history.push(ROUTES.LOGIN);
			});
	}, []);

	const getAllOffers = (page = 1) => {
		setOfferLoader(true);
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/getAllOffers?filter=${filters}&page=${page}&limit=10}`,
			withCredentials: true,
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		})
			.then((response) => {
				if (response.status === 200) {
					setGetOfferData(response.data.data?.items);
					setTotalItemsCount(response.data.data.totalItems);
					let count;
					if (response.data.data.totalItems < page * 10)
						count = response.data.data.totalItems;
					else count = page * 10;
					setCurrentPageCount(count);
				}
			})
			.finally(() => {
				setOfferLoader(false);
			});
	};

	useEffect(() => {
		const page = search.match(/\?page=(\d+)/);
		if (page) {
			getAllOffers(page[1]);
		}
	}, [filters, search]);

	function join(t, a, s) {
		function format(m) {
			const f = new Intl.DateTimeFormat("en", m);
			return f.format(t);
		}
		return a.map(format).join(s);
	}

	const handleClose = () => {
		setModalFlag(false);
		getAllOffers();
	};

	const handleLaunchOffer = () => {
		setOfferLoader(true);
		axios({
			method: "POST",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/readyForLaunch`,
			data: {
				offer_id: offerLaunchId,
				offer_name: offerLaunchName,
			},
			withCredentials: true,
		})
			.then((response) => {
				if (response.status === 200) {
					setReqModalFlag(false);
					toast.success(<FormattedMessage id="Your offer will launch soon" />);
					getAllOffers();
				}
			})
			.catch((err) => {
				setReqModalFlag(false);
				toast.error(
					<FormattedMessage id="Please provide proper information of offer" />,
				);
			})
			.finally(() => setOfferLoader(false));
	};
	const closeLaunchModal = () => {
		setReqModalFlag(false);
	};
	const handleReqClose = () => {
		setReqModalFlag(false);
	};
	const handleEndClose = () => {
		setEndModalFlag(false);
	};

	const handleEndOffer = () => {
		axios({
			method: "POST",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/endOffer`,
			data: {
				offer_id: endOfferId,
				offer_name: endOfferName,
			},

			withCredentials: true,
		})
			.then((response) => {
				if (response.status === 200) {
					const endOfferdataRemoveRow =
						activeOffersData &&
						activeOffersData.length > 0 &&
						activeOffersData.filter((data, index) => {
							return data.offer_id !== endOfferId;
						});

					setActiveOffersData(endOfferdataRemoveRow);
					setEndModalFlag(false);
					toast.success("Offer expired...");
				}
			})
			.catch((err) => {
				setEndModalFlag(false);
				toast.error(
					<FormattedMessage id="Please provide proper information of offer" />,
				);
			});
	};

	const closeEndModal = () => {
		setEndModalFlag(false);
	};

	const handleFilterChange = (filter) => {
		if (filter) {
			if (filter === "inactive") {
				setFilters("inactive");
			} else if (filter === "active") {
				setFilters("active");
			} else {
				setFilters("allOffers");
			}
		} else {
			setFilters("");
		}
	};

	const handleDownload = (file_id) => {
		axios({
			method: "GET",
			url:
				`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/getOfferDocument?fileId=${file_id}`,
			withCredentials: true,
		}).then((response) => {
			if (response.data.data.file_key) {
				axios({
					method: "GET",
					url:
						`${process.env[`REACT_APP_BACKEND_URL_${process.env.REACT_APP_ENV}`]}/assets/download?key=${response.data.data.file_key}`,
					withCredentials: true,
				})
					.then((res) => {
						const downloadFile = () => {
							const link = document.createElement("a");
							link.href = res.data.url;
							link.download = res.data.filename;
							document.body.appendChild(link);
							link.click();
							document.body.removeChild(link);
						};
						downloadFile();
					})
					.catch((err) => {
						toast.error(err.response.data.message);
					})
					.finally(() => {
						setLoading(false);
					});
			}
		});
	};

	return (
		<div
			className="vertical-layout vertical-menu-modern  navbar-floating footer-static bg-white "
			data-open="click"
			data-menu="vertical-menu-modern"
			data-col=""
		>
			<nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow customCss">
				<Header email={email} history={props.history} />
			</nav>
			<div
				className="main-menu menu-fixed menu-light menu-accordion menu-shadow"
				style={{ borderRight: "1px solid #dad8d8" }}
				data-scroll-to-active="true"
			>
				<SideNav history={props.history} />
			</div>

			<div className="app-content content page-main-div-wrapper d-none d-lg-block">
				<div className="content-overlay" />
				<div className="header-navbar-shadow" />
				<div
					className="content-header row"
					style={{ borderBottom: "1px solid #8888881A" }}
				>
					<div className="px-1 bg-white">
						<ul
							className="nav nav-pills "
							style={{
								backgroundColor: "yellow",
								borderRadius: "25px",
								height: "auto",
								alignItems: "center",
								paddingLeft: "8px",
								paddingRight: "8px",
								paddingTop: "3px",
								paddingBottom: "3px",
							}}
						>
							<li className="nav-item" onClick={() => handleFirstTab()}>
								<a
									className={
										`nav-link text-darkk${firstTabFlag ? " active no-padding" : ""}`
									}
									id="contact-tab"
									data-toggle="pill"
									aria-expanded="false"
								>
									<FormattedMessage id="Request" />
								</a>
							</li>
							<li className="nav-item" onClick={() => handleSecondTab()}>
								<a
									className={
										`nav-link text-darkk${!firstTabFlag ? " active no-padding" : ""}`
									}
									id="offers-tab"
									data-toggle="pill"
									aria-expanded="true"
								>
									<FormattedMessage id="Offer Details" />
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="content-wrapper">
					<div className="content-body">
						<div className="row">
							<div className="col-12">
								<div className="tab-content">
									<div
										role="tabpanel"
										className={
											`tab-pane mt-2${firstTabFlag ? " active" : ""}`
										}
										id="offer"
										aria-labelledby="offer-tab"
										aria-expanded="true"
									>
										<div className="card ">
											<div
												className="card-header"
												style={{ background: "#CCCCCC" }}
											>
												<div
													className="card-title"
													style={{ fontSize: "20px", color: "#000" }}
												>
													<FormattedMessage id="Contact Snap" />
												</div>
											</div>
											<div className="card-body mt-2">
												<form
													className="form"
													id="offerForm"
													onSubmit={handleSubmit(onSubmit)}
												>
													<div className="row col-12 col-md-8">
														<div className="col-12 col-md-6">
															<div className="form-group">
																<label
																	htmlFor="firstName"
																	className="form-label-style"
																	style={{
																		color: "#404040",
																		marginBottom: "6px",
																	}}
																>
																	<FormattedMessage id="First Name" />*
																</label>
																<input
																	disabled={loading}
																	type="text"
																	defaultValue={firstName}
																	id="firstName"
																	className="form-control"
																	name="firstName"
																	{...register(
																		"firstName",
																		{
																			required: true,
																			pattern: /^[a-zA-Z0-9\s]*$/,
																		},
																		{
																			validate: (value) => {
																				return !!value.trim();
																			},
																		},
																	)}
																/>
																{errors?.firstName?.type === "pattern" && (
																	<label
																		style={{
																			color: "red",
																			fontStyle: "normal",
																		}}
																	>
																		<FormattedMessage id="Alphabetical characters only" />
																	</label>
																)}
																{errors?.firstName?.type === "required" && (
																	<label
																		style={{
																			color: "red",
																			fontStyle: "normal",
																		}}
																	>
																		<FormattedMessage id="This field is required" />
																	</label>
																)}
															</div>
														</div>
														<div className="col-12 col-md-6">
															<div className="form-group">
																<label
																	htmlFor="lastName"
																	className="form-label-style"
																	style={{
																		color: "#404040",
																		marginBottom: "6px",
																	}}
																>
																	<FormattedMessage id="Last Name" />*
																</label>
																<input
																	disabled={loading}
																	type="text"
																	defaultValue={lastName}
																	id="lastName"
																	className="form-control"
																	name="lastName"
																	{...register("lastName", {
																		required: true,
																		pattern: /^[a-zA-Z0-9\s]*$/,
																	})}
																/>
																{errors?.lastName?.type === "pattern" && (
																	<label
																		style={{
																			color: "red",
																			fontStyle: "normal",
																		}}
																	>
																		<FormattedMessage id="Alphabetical characters only" />
																	</label>
																)}
																{errors?.lastName?.type === "required" && (
																	<label
																		style={{
																			color: "red",
																			fontStyle: "normal",
																		}}
																	>
																		<FormattedMessage id="This field is required" />
																	</label>
																)}
															</div>
														</div>
														<div className="col-6 col-md-12">
															<div className="form-group">
																<label
																	htmlFor="email"
																	className="form-label-style"
																	style={{
																		color: "#404040",
																		marginBottom: "6px",
																	}}
																>
																	<FormattedMessage id="Email*" />
																</label>
																<input
																	disabled={loading}
																	type="email"
																	defaultValue={email}
																	id="email"
																	className="form-control position-relative"
																	style={{ paddingLeft: "34px" }}
																	name="email"
																	spellCheck="false"
																	{...register("email", { required: true })}
																/>
																<Mail
																	style={{
																		position: "absolute",
																		top: "35",
																		left: "22",
																		width: "19px",
																		height: "19px",
																		color: "#737373",
																	}}
																/>
																{errors?.email?.type === "pattern" && (
																	<label
																		style={{
																			color: "red",
																			fontStyle: "normal",
																		}}
																	>
																		<FormattedMessage id="Alphabetical characters only" />
																	</label>
																)}
																{errors?.email?.type === "required" && (
																	<label
																		style={{
																			color: "red",
																			fontStyle: "normal",
																		}}
																	>
																		<FormattedMessage id="This field is required" />
																	</label>
																)}
															</div>
														</div>
														<div className="col-6 col-md-12">
															<div className="form-group">
																<label
																	htmlFor="type"
																	className="form-label-style"
																	style={{
																		color: "#404040",
																		marginBottom: "6px",
																	}}
																>
																	<FormattedMessage id="Type" />*
																</label>
																<div
																	style={{ display: "flex" }}
																	className="styled-select"
																>
																	<select
																		disabled={loading}
																		id="type"
																		className="w-100 rounded "
																		style={{
																			border: "#b2b2b2 1px solid",
																			color: "#1a1a1a",
																		}}
																		name="type"
																		spellCheck="false"
																		{...register("type", { required: true })}
																	>
																		<option value="" selected disabled hidden>
																			Select Type
																		</option>
																		<option value="Zero Rating">
																			{" "}
																			Zero Rating{" "}
																		</option>
																		<option value="Co-Marketing">
																			{" "}
																			Co-Marketing{" "}
																		</option>
																		<option value="Preloads"> Preloads </option>
																	</select>
																</div>
																{errors?.type?.type === "required" && (
																	<label
																		style={{
																			color: "red",
																			fontStyle: "normal",
																		}}
																	>
																		<FormattedMessage id="This field is required" />
																	</label>
																)}
															</div>
														</div>
														<div className="col-6 col-md-12">
															<div className="form-group">
																<label
																	htmlFor="description"
																	className="form-label-style"
																	style={{
																		color: "#404040",
																		marginBottom: "6px",
																	}}
																>
																	<FormattedMessage id="Description" />
																</label>
																<textarea
																	disabled={loading}
																	className="form-control"
																	id="description"
																	name="description"
																	rows="5"
																	spellCheck="false"
																	{...register("description", {
																		required: false,
																	})}
																/>
															</div>
														</div>
														<div className="col-12 mt-2 mb-2">
															<button
																type="submit"
																className="btn btn-snapchat"
																disabled={loading}
															>
																{loading && (
																	<Spinner size="sm" className="mr-1" />
																)}
																<FormattedMessage id="Submit" />
															</button>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
									<div
										className={
											`tab-pane mt-2 ${secondTabFlag ? " active" : ""}`
										}
										id="pending_launch"
										role="tabpanel"
										aria-labelledby="pending-tab"
										aria-expanded="false"
									>
										<div className="card" id="basic-table1">
											<div className="table-responsive">
												{secondTabFlag && (
													<div
														className="d-flex flex-column col-6 justify-content-center py-2"
														style={{ paddingLeft: "24px " }}
													>
														<div
															className="d-flex align-items-center"
															style={{ gap: "46px", display: "flex" }}
														>
															<label
																htmlFor="lastName"
																style={{
																	color: "#404040",
																	fontSize: "14px",
																	fontWeight: 500,
																}}
															>
																<FormattedMessage id="Filter By Status: " />
															</label>
															<div
																style={{
																	position: "relative",
																	display: "flex",
																}}
																className="styled-select"
															>
																<select
																	onChange={(e) =>
																		handleFilterChange(e.target.value)
																	}
																	placeholder="Select Status"
																	ref={selectRef}
																	style={{ color: "#404040" }}
																>
																	<option value="" selected disabled hidden>
																		Select Status
																	</option>
																	<option value="active">Active</option>
																	<option value="inactive">Inactive</option>
																</select>
																{!!selectRef.current?.value.length && (
																	<button
																		onClick={() => {
																			setFilters("allOffers");
																			selectRef.current.value = "";
																		}}
																		style={{
																			border: "none",
																			background: "white",
																			position: "absolute",
																			left: "96px",
																			top: "7px",
																		}}
																	>
																		<X
																			style={{
																				width: "12px",
																				height: "12px",
																				color: "darkgrey",
																			}}
																			className="clear-icon"
																		/>
																	</button>
																)}
															</div>
														</div>
														<label
															className="mt-2"
															style={{
																color: "#595959",
																fontSize: "14px",
																fontWeight: 400,
																lineHeight: "20.02px",
																letterSpacing: "0.17px",
															}}
														>
															Showing {currentPageCount} of {totalItemsCount}
														</label>
													</div>
												)}

												<table className="w-100">
													<thead className="bg-white ">
														<tr>
															<th
																style={{
																	width: "20%",
																	background: "#fff",
																	paddingLeft: "24px",
																}}
															>
																<FormattedMessage id="Offer Name" />
															</th>
															<th style={{ width: "20%", background: "#fff" }}>
																<FormattedMessage id="Type" />
															</th>
															<th style={{ width: "10%", background: "#fff" }}>
																<FormattedMessage id="Launch Date" />
															</th>
															<th style={{ width: "10%", background: "#fff" }}>
																<FormattedMessage id="End Date" />
															</th>
															<th style={{ width: "15%", background: "#fff" }}>
																<FormattedMessage id="Status" />
															</th>
															<th style={{ width: "10%", background: "#fff" }}>
																<FormattedMessage id="Last Updated" />
															</th>
															<th style={{ width: "20%", background: "#fff" }}>
																<FormattedMessage id="Documents" />
															</th>
														</tr>
													</thead>

													{offerLoader ? (
														<td
															colSpan={8}
															style={{
																textAlign: "center",
																paddingTop: "24px",
															}}
														>
															<Spinner
																style={{
																	height: "60px",
																	width: "60px",
																	fontSize: "24px",
																}}
															/>
														</td>
													) : getOfferData && getOfferData.length > 0 ? (
														<tbody>
															{getOfferData &&
																getOfferData.length > 0 &&
																getOfferData.map((data, index) => {
																	return (
																		<>
																			<tr
																				key={index}
																				style={{
																					marginBottom: "20px",
																					backgroundColor:
																						(index + 1) % 2 && "#F2F2F2",
																					height: "68px",
																				}}
																			>
																				<td
																					style={{
																						paddingLeft: "24px",
																					}}
																				>
																					{data.offer_name?.length ? (
																						<span>{data.offer_name}</span>
																					) : (
																						<span>
																							<Minus
																								style={{
																									width: "50px",
																									height: "20px",
																								}}
																							/>
																						</span>
																					)}
																				</td>
																				<td>
																					{data.types_of_offer?.length ? (
																						<span>{data.types_of_offer}</span>
																					) : (
																						<span>
																							<Minus
																								style={{
																									width: "50px",
																									height: "20px",
																								}}
																							/>
																						</span>
																					)}
																				</td>
																				<td>
																					<span>
																						{data.launch_date
																							? join(
																									new Date(
																										`${data.launch_date}`,
																									),
																									[
																										{ day: "numeric" },
																										{ month: "short" },
																										{ year: "numeric" },
																									],
																									"-",
																								)
																							: "NA"}
																					</span>
																				</td>
																				<td>
																					<span className="d-flex ">
																						{data.end_date
																							? join(
																									new Date(`${data.end_date}`),
																									[
																										{ day: "numeric" },
																										{ month: "short" },
																										{ year: "numeric" },
																									],
																									"-",
																								)
																							: "NA"}
																					</span>
																				</td>
																				<td>
																					<div
																						className="badge-pill d-flex align-items-center px-1"
																						style={{
																							background:
																								data.ready_for_launch_flag ===
																								"launch"
																									? "#ECFDF3"
																									: "#FEF3F2",
																							color:
																								data.ready_for_launch_flag ===
																								"launch"
																									? "#027A48"
																									: "#B42318",
																							width: "fit-content",
																						}}
																					>
																						<Dot />
																						{data.ready_for_launch_flag ===
																						"launch"
																							? "Active"
																							: "Inactive"}
																					</div>
																				</td>
																				<td>
																					<span>
																						{data.updatedAt
																							? join(
																									new Date(`${data.updatedAt}`),
																									[
																										{ day: "numeric" },
																										{ month: "short" },
																										{ year: "numeric" },
																									],
																									"-",
																								)
																							: "NA"}
																					</span>
																				</td>
																				<td>
																					{data.file_id ? (
																						<span>
																							<button
																								className="btn-download"
																								onClick={() =>
																									handleDownload(data.file_id)
																								}
																							>
																								<svg
																									width="18"
																									height="18"
																									viewBox="0 0 18 18"
																									fill="none"
																									xmlns="http://www.w3.org/2000/svg"
																								>
																									<path
																										d="M16.2998 11.5V14.8333C16.2998 15.2754 16.1242 15.6993 15.8116 16.0118C15.4991 16.3244 15.0752 16.5 14.6331 16.5H2.96647C2.52444 16.5 2.10052 16.3244 1.78796 16.0118C1.4754 15.6993 1.2998 15.2754 1.2998 14.8333V11.5M4.63314 7.33333L8.7998 11.5M8.7998 11.5L12.9665 7.33333M8.7998 11.5V1.5"
																										stroke="white"
																										stroke-width="1.67"
																										stroke-linecap="round"
																										stroke-linejoin="round"
																									/>
																								</svg>
																								Download
																							</button>
																						</span>
																					) : null}
																				</td>
																			</tr>
																		</>
																	);
																})}
														</tbody>
													) : (
														<tbody>
															<tr>
																<td
																	colSpan="8"
																	className="text-center font-weight-bolder py-5"
																	style={{ fontSize: "16px", color: "#000" }}
																>
																	<FormattedMessage id="No Records Found" />
																</td>
															</tr>
														</tbody>
													)}
												</table>
												<div className="mt-2">
													<TablePagination
														pageSize={10}
														itemCount={totalItemsCount}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal show={endModalFlag} onHide={handleEndClose} centered>
					<Modal.Body>
						<div className="col-lg-12 text-center font-weight-bold text-darkk py-2 mt-2">
							<p>
								<FormattedMessage id="Are you sure you want to end the offer?" />
							</p>
						</div>
						<div className="col-lg-12 text-center mb-3">
							<button
								type="button"
								className="btn btn-snapchat border-25 mb-2"
								style={{ padding: "15px 110px" }}
								data-dismiss="modal"
								onClick={() => handleEndOffer()}
							>
								<FormattedMessage id="End Offer" />
							</button>
							<button
								type="button"
								className="btn btn-outline bg-dark border-25"
								style={{ padding: "15px 110px", color: "yellow" }}
								data-dismiss="modal"
								onClick={closeEndModal}
							>
								<FormattedMessage id="Cancel" />
							</button>
						</div>
					</Modal.Body>
				</Modal>

				<Modal show={reqModalFlag} onHide={handleReqClose} centered>
					<Modal.Body>
						<div className="col-lg-12 text-center font-weight-bold text-darkk py-2 mt-2">
							<p>
								<FormattedMessage id="Are you sure you want to launch the offer?" />
							</p>
						</div>
						<div className="col-lg-12 text-center mb-3">
							<button
								type="button"
								className="btn btn-snapchat border-25 mb-2"
								style={{ padding: "15px 110px" }}
								data-dismiss="modal"
								onClick={() => handleLaunchOffer()}
							>
								<FormattedMessage id="Launch Offer" />
							</button>
							<button
								type="button"
								className="btn btn-outline bg-dark border-25"
								style={{ padding: "15px 110px", color: "yellow" }}
								data-dismiss="modal"
								onClick={closeLaunchModal}
							>
								<FormattedMessage id="Cancel" />
							</button>
						</div>
					</Modal.Body>
				</Modal>

				<div className="sidenav-overlay" />
				<div className="drag-target" />
			</div>
			<MarketingModal
				handleClose={handleClose}
				modalFlag={modalFlag}
				selectedOfferId={selectedOfferId}
			/>
			<div
				className="fullscreenDiv d-xl-none d-lg-none d-md-block d-sm-block d-xs-block"
				style={{ textAlign: "center" }}
			>
				<div className="center text-dark font-weight-bolder">
					<h1>
						<FormattedMessage id="Works best on Desktop" />{" "}
					</h1>
				</div>
			</div>
		</div>
	);
};
export default OfferPage;
